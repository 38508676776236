import jwtDecode from "jwt-decode";

// idMenu in realtà sarebbe idUser ed in base allo user avremo sidebar differenti
const checkTokenExpiration = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const isExpired = (new Date()).valueOf() >= (jwtDecode(token).exp * 1000);
    if (isExpired) {
      localStorage.clear();
      window.location.reload();
    }
    return isExpired;
  } else {
    return true;
  }
}


export const AuthService = {
  checkTokenExpiration
}

export default AuthService;
