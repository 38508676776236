/* eslint-disable */
import React from "react";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import { constants } from "../../config/config";
import WorkflowsService from "../../service/workflowsService";
import RenderTemplateForm from "../RenderView/RenderTemplateForm";

const AssigneeModal = ({
  dataToShow,
  show,
  handleClose
}) => {
  const formData = {
    cancel_caption: 'Ripulisci',
    submit_caption: 'Conferma',
    rows:[{
      fields: [ {
        size: 12,
        name: 'isUser',
        label: 'Assegna ad un utente',
        type: 'checkbox'
      }]
    },
    {
      fields: [ {
        size: 12,
        name: 'assignee',
        label: 'Nuovo assegnatario',
        type: 'text',
        placeholder: 'Inserisci...'
      }]
    }]
  };

  const formFields = {
    assignee: '',
    isUser: false
  }

  const submit = async (formValues) => {
    const data = {
      taskId: dataToShow.instance,
      isPortalUser: formValues.isUser,
      assignee: formValues.assignee
    }

    const res = await WorkflowsService.reassign(data);
    if (res && res.status == 200) {
      window.location.reload();
    } else {
      HandleErrorService.showErrorAlert(res && res.errors ? res.errors[0] : constants.defaultErrorMessage);
    }
  }

  return (
    <Modal open={show} hideModal={handleClose}>
      <ModalHeader>
        <span>Assegna attività</span>
        <span className="float-right cursor-pointer" onClick={() => handleClose()}>x</span>
      </ModalHeader>
      <ModalBody>
        <RenderTemplateForm formData={formData} formFields={formFields} submit={submit}></RenderTemplateForm>
      </ModalBody>
    </Modal>
  );

};

export default AssigneeModal;
