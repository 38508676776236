/* eslint-disable */
import React from "react";
import { withRouter } from 'react-router';



class AssertionPage extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount(){
      console.log("Proprietà",this.props);

  }






  render() {


    return (<>
    </>
    );
  }

}

export default withRouter(AssertionPage);
