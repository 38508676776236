class Column {
  title = '';
  className = '';
  propertyName = '';
  isSortable = false;

  constructor(title, className = '', propertyName = '', isSortable = false) {
    this.title = title;
    this.className = className;
    this.propertyName = propertyName;
    this.isSortable = isSortable;
  }
}

class ColumnData {
  dataToDisplay = null;
  className = '';
  realValue = '';
  isHtml = false;
  isDate = false;

  constructor(dataToDisplay, className = '', realValue, isHtml, isDate) {
    this.dataToDisplay = dataToDisplay;
    this.className = className;
    this.realValue = realValue;
    this.isHtml = isHtml;
    this.isDate = isDate;
  }
}

export {Column, ColumnData}
