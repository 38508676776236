/* eslint-disable */
import React, {useEffect, useState} from "react";
import { Container, Row, Button } from "shards-react";
import { useParams, useHistory } from "react-router-dom";

import PageTitle from "../../components/common/PageTitle";
import WorkflowsService from "../../service/workflowsService";
import ActiveWorkflowTable from "./ActiveWorkflowTable";
import ClosedWorkflowTable from "./ClosedWorkflowTable";
import ToggleLoaderService from "../../service/toggleLoaderService";
import { constants } from "../../config/config";
import WorkflowDashbaord from "./WorkflowDashboard";

const Workflows = () => {
  const {processKey} = useParams();
  const [activeProcessList, setActiveProcessList] = useState();
  const [concludedProcessList, setConcludedProcessList] = useState();
  const [instanceStart, setInstanceStart] = useState();
  const [mainInfo, setMainInfo ] = useState();
  const [canAssign, setCanAssign] = useState();
  const [dashboardData, setDashboardData] = useState();

  const history = useHistory();

  useEffect(() => {
    async function getStatus() {
      ToggleLoaderService.showLoader();
      const data = await WorkflowsService.getStatus(processKey);
      // .catch((err) => console.log('ERROR', err));
      if (data && data.status == 200 && data.contents[0]) {
        const contents = data.contents[0];

        setCanAssign(contents.canAssign);

        const tmpActiveProcessList = [];
        if (contents.activeProcess && contents.activeProcess[processKey]) {
          for (const key in contents.activeProcess[processKey].instances) {
            const currentItem = contents.activeProcess[processKey].instances[key];
            currentItem.tasks.forEach(task => {
              tmpActiveProcessList.push({id: key, ...task});
            });
          }
        }

        setActiveProcessList(tmpActiveProcessList);
        setConcludedProcessList({
          data: contents.historyProcess,
          extraColumns: contents.historyProcessSpecificFields});

        setDashboardData(contents.historyProcess);

        setInstanceStart(contents.instanceStart);
        setMainInfo({
          processDescription: contents.processDescription,
          processKey: contents.processKey,
          processType: contents.processType,
          type: contents.type
        });

      } else {
        HandleErrorService.showErrorAlert(data && data.errors ? data.errors[0] : constants.defaultErrorMessage);
      }
      ToggleLoaderService.hideLoader();
    }

    getStatus();

  }, [processKey]);

  const startNewProcess = async () => {
    const processStarted = await WorkflowsService.startNewProcess(processKey);
    // .catch((err) => console.log('ERROR', err));
    history.push('/task/' + processKey,
      {
        templateForm: processStarted.contents[0]
    });
  }

  return <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4 justify-content-between">
      <PageTitle sm="4" title={mainInfo && mainInfo.processDescription} className="text-sm-left" />
      {instanceStart && instanceStart.userStartable && <Button className="btn-start-process btn-success" type="button" onClick={async () => await startNewProcess()}> {instanceStart.starterButtonCaption ?  instanceStart.starterButtonCaption  : 'Nuovo Ticket' }</Button>}

    </Row>
    {activeProcessList && dashboardData && <WorkflowDashbaord numberActiveProcesses={activeProcessList.length} concludedProcessList={dashboardData}></WorkflowDashbaord>}
    {activeProcessList && <ActiveWorkflowTable dataToShow={activeProcessList} processKey={processKey} canAssign={canAssign}></ActiveWorkflowTable>}
    {concludedProcessList && <ClosedWorkflowTable dataToShow={concludedProcessList}></ClosedWorkflowTable>}

  </Container>
};

export default Workflows;
