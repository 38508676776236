/* eslint-disable */
import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import MenuService from "../../../service/menuService";
import { constants } from "../../../config/config";
import HandleErrorService from "../../../service/handleErrorService";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    this.setState({
      ...this.state,
      navItems: await this.getMenuItems()
    });
  }

  componentWillMount() {
    Store.addMenuChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeMenuChangeListener(this.onChange);

  }

  async onChange() {
    if (Store.getReloadMenu()) {
      this.setState({
        ...this.state,
        navItems: await this.getMenuItems()
      });
    } else {
      this.setState({
        ...this.state
      });
    }

  }

  async getMenuItems() {
    const menuItems = await MenuService.getMenu();
    if (menuItems && menuItems.status == 200)  {
      Store.reloadMenuCompleted();
      return menuItems.menuItems;
    } else {
      HandleErrorService.showErrorAlert(menuItems && menuItems.errors ? menuItems.errors[0] : constants.defaultErrorMessage);
    }
  }

  render() {
    const { navItems: items } = this.state;
    const {menuVisible} = this.props;
    return (
      <div className="nav-wrapper menu-nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items && items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} menuVisible={menuVisible}/>
          ))}
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
