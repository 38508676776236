/* eslint-disable */
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Card, Col, Button } from "shards-react";
import HandleErrorService from "../../service/handleErrorService";
import NewsService from "../../service/newsService";
import RenderTemplateForm from "../RenderView/RenderTemplateForm";

const AddNews = () => {
  const history = useHistory();
  const location = useLocation();
  const { renderViewId } = location.state;


  const formData = {
    cancel_caption: 'Ripulisci',
    submit_caption: 'Aggiungi',
    title: 'Aggiungi Notizia',
    rows:[
    {
      fields: [ {
        size: 12,
        name: 'img',
        label: 'Immagini',
        type: 'file'
      }]
    },
    {
      fields: [ {
        size: 6,
        name: 'title',
        label: 'Titolo',
        type: 'text',
        placeholder: 'Inserisci titolo...'
      },
      {
        size: 6,
        name: 'pub_date',
        label: 'Data di pubblicazione',
        type: 'date',
        placeholder: new Date().toLocaleString()
      }]
    },
    {
      fields: [ {
        size: 12,
        name: 'text',
        label: 'Descrizione',
        type: 'textarea',
        placeholder: 'Inserisci descrizione...'
      }]
    }]
  };

  const formFields = {
    title: '',
    text: '',
    img: '',
    pub_date: ''
  }

  const submit = async (data) => {
    data.preventDefault();
    const formValues = new FormData(data.target);
    const res = await NewsService.addNews(formValues);

    if (res && res.status == 200) {
      goBack();
    } else {
      // window.alert('Non è stato possibile aggiungere la notizia.');
      HandleErrorService.showErrorAlert('Non è stato possibile aggiungere la notizia.')
    }
  }

  const goBack = () => {
    return history.push('/renderView/' + renderViewId);
  }

  return (<Container>
    <Button className="mt-4 btn-success" onClick={() => goBack()}>Torna indietro</Button>
    <Card className="p-4 mt-2">
      <Col>
        <RenderTemplateForm formData={formData} onSubmit={submit} formFields={formFields}></RenderTemplateForm>
      </Col>
    </Card>
  </Container>
  );
}

export default AddNews;
