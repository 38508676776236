import React from "react";
import { Col, Row } from "shards-react";
import RenderChart from "./RenderChart";
import RenderIndicator from "./RenderIndicator";

const RenderDashboard = ({
  items
}) => {

  const types = {
    'CROWN': 'doughnut',
    'PIE': 'pie',
    'BAR': 'bar',
    'LINE': 'line',
    'WEDGE': 'gauge'
  }

  const renderItem = (item, key) => {
    item.type = types[item.type];
    switch (item.component) {
      case 'CHART':
        return <RenderChart key={key} data={item}></RenderChart>
      case 'INDICATOR':
        return <RenderIndicator key={key} data={item}></RenderIndicator>
      default:
        break;
    }
  }

  return (
    <Row style={{'align-items': 'end'}}>
      {items && items.length > 0 && items.map((item, index) =>
        <Col md={3}>
          <h4>{item.title}</h4>
          {renderItem(item, index)}
        </Col>
      )}
    </Row>
  );
}

export default RenderDashboard;
