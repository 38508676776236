import React, { useEffect, useState } from "react";
import { Container,
// ListGroup, ListGroupItem
} from "shards-react";

import { useParams } from "react-router-dom";
import PagesService from "../../service/pagesService";
import News from "../News/News";
import RenderTemplateForm from "./RenderTemplateForm";
import RenderHTMLTemplate from "./RenderHTMLTemplate";
import RenderDashboard from "./RenderDashboard";
// import ToggleLoaderService from "../../service/toggleLoaderService";
import getFakeNews from '../../data/fake-news';
import { constants } from "../../config/config";
const newsId = constants.newsId;

const RenderView = () => {
  const {id} = useParams();
  const [contents, setContents] = useState();
  const fakeNews = getFakeNews();

  useEffect(() => {
    async function getContent() {
      const data = await PagesService.getContent(id);
      setContents(data.contents);
    }

    if (id === newsId) {
      setContents(fakeNews.contents);
    } else {
      getContent();
    }
    // .catch((err) => console.log('ERROR', err));


  }, [id]);

  const renderContentView = (content, key) => {
    switch (content.type) {
      case 'DASHBOARD':
        return <RenderDashboard key={key} items={content.content}></RenderDashboard>
      case 'HTML':
        // TODO: ADD PROPS TEMPLATE!
        return <RenderHTMLTemplate></RenderHTMLTemplate>
      case 'WORKFLOW_FORM' || 'WIZARD_FORM':
        return <RenderTemplateForm key={key} formData={content.content}></RenderTemplateForm>;
      case 'NEWS':
        return <News key={key} news={fakeNews.contents[0].items} canManage={content.canManage}></News>;
      default:
        break;
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
        {
          contents && contents.length > 0 && contents.map((content, index) =>
            // <ListGroup className="m-2" key={index}>
            //   <ListGroupItem className="p-3">
                renderContentView(content, index)
            //   </ListGroupItem>
            // </ListGroup>
          )
        }
        {(!contents || contents.length === 0) &&
          <div className="text-center mt-4">Non ci sono elementi da mostrare.</div>
        }
    </Container>
  );
}

export default RenderView;
