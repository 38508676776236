/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import HandleErrorService from '../handleErrorService';
// import { constants } from "../../config/config";
// import differenceInSeconds from 'date-fns/differenceInSeconds';


// const urlAuth = `${constants.api.url}api/authentication`;
// let refreshingTokenAt = null;
// let redirectWhenExpired = null;

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
    baseURL: '',
    headers: {
        'Content-type': 'application/json'
    },
});

const requestHandler = request => {
    const jwtToken = 'Bearer ' + localStorage.getItem("token");
    if (jwtToken) {
        if (jwtDecode(jwtToken).exp < (Date.now() / 1000)) {
          localStorage.clear();
          window.reload();
        } else {
          request.headers['Authorization'] = jwtToken;
        }
    }

    return request;
};

client.interceptors.request.use(requestHandler);

// const showErrorMessage = (expired, error) => {
//     console.warn({ expired, error });
//     if (expired && redirectWhenExpired) {
//         const url = redirectWhenExpired.toLowerCase();
//         if (url.startsWith('https://') || url.startsWith('http://')) {
//             window.location.href = redirectWhenExpired;
//         } else {
//             window.location.pathname = redirectWhenExpired;
//         }
//         redirectWhenExpired = null;
//     } else if (expired && window.location.pathname?.toLowerCase() !== '/error') {
//         const apiUrl = error?.config?.url?.toLowerCase();
//         if (apiUrl && noErrorAPIs.find(x => apiUrl.indexOf(x) === -1)) {
//             localStorage.setItem('lastError', JSON.stringify(error, null, 2));
//             // window.location.pathname = '/Error';
//         }
//     }
// };

// const refreshToken = async (failedRequest, failedResponse) => {
//     if (failedResponse?.status !== 401) {
//         return false;
//     }

//     if (failedRequest._retried) {
//         return false;
//     }

//     failedRequest._retried = true;

//     const refreshToken = localStorage.getItem('RefreshToken');
//     if (!refreshToken) {
//         return false;
//     }

//     const idUser = localStorage.getItem('idUser');
//     if (!idUser) {
//         return false;
//     }

//     if (refreshingTokenAt) {
//         const waitForRefresh = () => new Promise(
//             resolve => setTimeout(async () => {
//                 if (!refreshingTokenAt) {
//                     resolve(!!localStorage.getItem('Token'));
//                 } else if (differenceInSeconds(new Date(), refreshingTokenAt) > 10) {
//                     resolve(false);
//                 } else {
//                     resolve(await waitForRefresh());
//                 }
//             }, 100)
//         );
//         if (await waitForRefresh()) {
//             failedRequest.headers['Authorization'] = localStorage.getItem('Token');
//             return true;
//         } else {
//             return false;
//         }
//     } else {
//         refreshingTokenAt = new Date();
//     }

//     try {
//         const newTokens = await client.get(
//             `${urlAuth}/refresh-token/${idUser}/${encodeURIComponent(refreshToken)}`
//         );
//         localStorage.setItem('Token', newTokens.data.jwtToken);
//         localStorage.setItem('RefreshToken', newTokens.data.refreshToken);
//         failedRequest.headers['Authorization'] = newTokens.data.jwtToken;
//         return true;
//     } catch (ex) {
//         if (ex.response?.status === 401) {
//             localStorage.removeItem('Token');
//             localStorage.removeItem('RefreshToken');
//             localStorage.removeItem('idUser');
//             localStorage.setItem('isLogin', false);
//             if (ex.response?.data?.data?.roleLabel?.toLowerCase() === 'tenantadmin') {
//                 redirectWhenExpired = constants.paths.comworkLogin();
//             } else {
//                 redirectWhenExpired = '/App/Login';
//             }
//         }
//         console.error('cannot refresh tokens', { error: ex, response: ex.response });
//         return false;
//     } finally {
//         refreshingTokenAt = null;
//     }
// };

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
    HandleErrorService.hideErrorAlert();
    const onSuccess = function (response) {
        // eslint-disable-next-line no-console
        return response.data
    };

    // const onError = async function (error) {
    //     // if (await refreshToken(error.config, error.response)) {
    //     //     try {
    //     //         error.config.data = options.data;
    //     //         return onSuccess(await client(error.config));
    //     //     } catch (ex) {
    //     //         logFailure(ex);
    //     //         showErrorMessage(false, ex);
    //     //     }
    //     // } else {
    //     logFailure(error);
    //     //     showErrorMessage(true, error);
    //     // }
    //     return await Promise.reject(error.response || error.message);
    // };

    // const logFailure = function (error) {
    //     console.error('Request Failed:', error.config);
    //     if (error.response) {
    //         // Request was made but server responded with something
    //         // other than 2xx
    //         console.error('Status:', error.response.status);
    //         console.error('Data:', error.response.data);
    //         console.error('Headers:', error.response.headers);
    //     } else {
    //         // Something else happened while setting up the request
    //         // triggered the error
    //         console.error('Error Message:', error.message);
    //     }
    // };

    return client(options).then(onSuccess);
    // .catch((err) => HandleErrorService.showErrorAlert(err.response.data.status && err.response.data.error ? err.response.data.status + ' ' + err.response.data.error : JSON.stringify(err)));
};

export default request;
