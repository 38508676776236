import request from "./base/baseService";
import { constants } from "../config/config";

let url = `${constants.api.url}`;
let notificationRoute = constants.api_routes.notification;

const getNotifications = async () => {
  const iterationId = 111111111111111111;

    return await request({
      url: `${url}${notificationRoute.url}`,
      method: notificationRoute.method,
      data: {
        iterationId,
        action: 'update'
      }
    })
}


export const NotificationsService = {
  getNotifications
}

export default NotificationsService;
