/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import {
  Col,
  Card,
  CardBody
} from "shards-react";
import { withRouter } from 'react-router';

class NewsLittleCardPost extends React.Component {

  render() {
    const {
      post,
      // canManage
    } = this.props;
    return (
      <Col md="4" sm="12" className="mb-4"
      // onClick={() => this.goToNewsDetailPage(post)}
      >
        <Card small className={"card-post card-post--1 little-news-container"}>
          <div className="news-list-image d-flex align-items-end" style={{background: "url(" + require('../../images/' + post.images[0].filename) + ")"}}>
            <div className="news-card-category-pill-container ml-3 mb-3">
              <span className="news-card-category-pill">{post.category}</span>
            </div>
          </div>
            {/* {post.images && post.images[0] &&
              <img className="news-list-image" src={post.images[0].bytes ? `data:image/png;base64,${post.images[0].bytes}` : require('../../images/' + post.images[0].filename)} alt={post.images[0].alt}/>
            } */}
          <CardBody className="news-card-body-container">
            {/* <span className="card-date">{new Date(post.date).toLocaleDateString()}</span> */}
            <span className="card-date">{post.date}</span>
            <h5 className="card-title">
              {post.title}
            </h5>
            {post.text &&
              <div className="news-body-container">
                <p className="card-text d-inline-block mb-3">{post.text}</p>
              </div>
            }
          </CardBody>
        </Card>
      </Col>
  )}
}

export default withRouter(NewsLittleCardPost);
