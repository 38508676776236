import { azuresso_msalConfig , azuresso_loginRequest , azuresso_graphConfig } from "./config/config";


export const msalConfig = {
    auth: {
      clientId: azuresso_msalConfig.auth.azuresso_clientId,
      //REVO Portal
    //   authority: "https://vpninlet-to-httc-infoedge.mywire.org/common.microsoft.com", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      authority: azuresso_msalConfig.auth.azuresso_authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: azuresso_msalConfig.auth.azuresso_redirectUri,
    },
    cache: {
      cacheLocation: azuresso_msalConfig.cache.azuresso_cacheLocation, // This configures where your cache will be stored
      storeAuthStateInCookie: azuresso_msalConfig.cache.azuresso_storeAuthStateInCookie, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: azuresso_loginRequest.azuresso_scopes
  };

  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: azuresso_graphConfig.azuresso_graphMeEndpoint
  };
