/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Alert } from "shards-react";
import SockJsClient from 'react-stomp';

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import { Store } from "../flux";
import WebSocketService from "../service/webSocketService";
import { constants } from "../config/config";
import { useEffect } from "react";
import AuthService from "../service/authService";
import { useIsAuthenticated } from "@azure/msal-react";

import { ProfileContent } from  "../components/utils/ProfileContent";

const DefaultLayout = ({ children, noNavbar, showSearchbar }) => {
  const [menuVisible, setMenuVisible] = useState(Store.getMenuState());
  // const [menuId] = useState(localStorage.getItem('menuId'));
  const url = constants.api.url_out;
  const wsockRoute = constants.api_routes.wsock;
  const [showLoader, setShowLoader] = useState(true);
  const [alertMessage, setAlertMessage] = useState();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    Store.addChangeLoaderListener(onChange);
    Store.addChangeAlertPopupVisibilityListener(onChangeShowAlert);

    const currentPath = (window.location.pathname).split('/');

    for (let i = 0; i < currentPath.length; i++) {
      const path = currentPath[i];
      if (constants.componentsWithoutSpinnerLoader[path]) {
        setShowLoader(false);
        break;
      }
    }

    // Hide loader after 20 seconds
    setTimeout(() => {
      setShowLoader(false);
    }, 20000);

    // Anything in here is fired on component unmount.
    return () => {
      Store.removeChangeLoaderListener(onChange);
      Store.removeChangeLoaderListener(onChangeShowAlert);
    }

  }, []);

  const onChange = () => {
    setShowLoader(Store.getShowLoader());
  }

  const onChangeShowAlert = () => {
    setAlertMessage(Store.getShowAlertPopup());
  }

  const onConnected = () => {
    console.log('Connect!');
  }

  const onMessageReceived = (msg) => {
    const messages = JSON.parse(msg.body);
    WebSocketService.handleSocketEvents(messages);
  }
// UTILIZZARE SUB1 NEL TOKEN COME USERNAME
  return (<>
    {showLoader && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
    <Container fluid>
      <SockJsClient
        url={`${url}${wsockRoute.url}`}
        topics={['/queue/messages']}
        onConnect={onConnected}
        onDisconnect={console.log("Disconnected!")}
        onMessage={msg => onMessageReceived(msg)}
        debug={false}
      />
      <Row>
        {/* { isAuthenticated && <ProfileContent /> } */}
        {/* {!isAuthenticated && !AuthService.checkTokenExpiration() && */}
        {!AuthService.checkTokenExpiration() &&
        <>
          <MainSidebar updateMenuVisible={(value) => setMenuVisible(value)} />
          <Col
            className="main-content p-0"
            // style={{marginLeft: menuVisible ? '16.5em' : '5em'}}
            style={{marginLeft:'7.2em'}}
            tag="main"
          >
            <Alert className={(!alertMessage ? "hide-alert hide-error-alert" : "show-alert show-error-alert show-error-dashboard-alert") + " col-12 task-alert"} theme="danger">
              <i className="fa fa-info mx-2"></i> {alertMessage}
            </Alert>
            {!noNavbar && <MainNavbar showSearchbar = {showSearchbar} />}
            {children}
          </Col>
        </>
        }

        {/* NOT AUTHENTICATED */}
        {/* {AuthService.checkTokenExpiration() && !isAuthenticated && */}
        {AuthService.checkTokenExpiration() &&
          <Col>
            <Alert className={(!alertMessage ? "hide-alert hide-error-alert" : "show-alert show-error-alert") + " col-12 task-alert"} theme="danger">
              <i className="fa fa-info mx-2"></i> {alertMessage}
            </Alert>
            {children}
          </Col>
        }
      </Row>
    </Container>
  </>
  )
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
  showSearchbar: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: true
};

export default DefaultLayout;
