/* eslint-disable */
import React from "react";
import { Modal, ModalBody, ModalHeader } from "shards-react";

const TasksPathsModal = ({
  dataToShow,
  show,
  handleClose
}) => {

  const formattedDuration = (millis) => {
    let res = '';

    const seconds = Math.floor((millis / 1000) % 60);
    const minutes = Math.floor((millis / (1000 * 60)) % 60);
    const hours = Math.floor((millis / (1000 * 60 * 60)) % 24);
    const days = Math.floor((millis / (1000 * 60 * 60 * 24)) )

    days.toString().padStart(2, "0");
    hours.toString().padStart(2, "0");
    minutes.toString().padStart(2, "0");
    seconds.toString().padStart(2, "0");

    if (days > 0) {
      res = days + (days == 1 ? ' giorno' : ' giorni');
    }

    if (hours > 0) {
      res += (res ? ' e ' : '') + hours + (hours == 1 ? ' ora' : ' ore');
    }

    if (minutes > 0 && !days) {
      res += (res ? ' e ' : '') + minutes + ' min.';
    }

    if (seconds > 0 && !hours) {
      res += (res ? ' e ' : '') + seconds + ' sec.';
    }

    return res;
  }

  return (
    <Modal open={show} hideModal={handleClose} size={'lg'} className="tasks-path-modal">
      <ModalHeader>
        <span>Percorso Tasks</span>
        <span className="float-right cursor-pointer" onClick={() => handleClose()}>x</span>
      </ModalHeader>
      <ModalBody>
        <table className="table tasks-modal-table">
          <thead>
            <tr>
              <th className="tasks-modal-table-number-col">N°</th>
              <th>Nome task</th>
              <th>Worker</th>
              <th className="tasks-modal-table-date-col">Data inizio</th>
              <th className="tasks-modal-table-date-col">Data fine</th>
              <th className="tasks-modal-table-date-col">Durata</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataToShow && dataToShow.tasks && dataToShow.tasks.map((task, idx) => {
              let phaseColor = '';
              let taskState = '';

              if (dataToShow.processState == 'EXTERNALLY_TERMINATED') {
                phaseColor =   'modal-phase-deleted-description';
                taskState = 'EXTERNALLY_TERMINATED';
              } else {
                if (task.endTime) {
                  phaseColor =   'modal-phase-completed-description';
                  taskState = 'Completato';
                } else {
                  phaseColor =   'modal-phase-in-progress-description';
                  taskState = 'In corso';
                }
              }

              return <tr className={phaseColor} key={idx}>
                  <td className={" tasks-modal-table-number-col" }>{idx + 1} </td>
                  <td className={" " }>{task.name} </td>
                  <td className={"  "}>{task.worker} </td>
                  <td className="tasks-modal-table-date-col">{new Date(task.startTime).toLocaleString()}</td>
                  <td className="tasks-modal-table-date-col">{task.endTime ? new Date(task.endTime).toLocaleString() : ''}</td>
                  <td className="tasks-modal-table-date-col">{task.duration ? formattedDuration(task.duration) : ''}</td>
                </tr>
            })}
          </tbody>
        </table>

      </ModalBody>
    </Modal>
  );

};


export default TasksPathsModal;
