import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Button, Col, Card, CardHeader, CardBody } from "shards-react";

const TaskCompleted = () => {
  const {processKey} = useParams();

  const backToTheProcessList = () => {
    return window.location.href = '/workflows/status/' + processKey;
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card small className="mb-4 mt-4">
            <CardHeader>
                <h4 className="text-center">Hai completato tutte le attività di questo processo.</h4>
            </CardHeader>
            <CardBody className="p-0 pb-3 m-auto">
              <Button onClick={() => backToTheProcessList()} className="btn-start-process btn-success">Torna alla lista dei processi</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default TaskCompleted;
