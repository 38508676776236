import request from "./base/baseService";
import { constants } from "../config/config";

let url = `${constants.api.url}`;
let contentRoute = constants.api_routes.content;

const getPage = async (idMenuPage) => {
    return await request({
        url: `${url}/page/${idMenuPage}`,
        method: 'GET'
    })
}

const getContent = async (id, contentRef = null) => {
  const iterationId = 111111111111111111;
  if (contentRef) {
    return await request({
      url: `${url}${contentRoute.url}`,
      method: contentRoute.method,
      data: contentRef
    });
  } else {
    return await request({
      url: `${url}${contentRoute.url}`,
      method: contentRoute.method,
      data: {
        iterationId,
        contentRef: id
      },
    });
  }
}
export const PagesService = {
  getPage,
  getContent
}

export default PagesService;
