import React, { useEffect } from "react";
import {useHistory } from "react-router-dom";
import {
  Container,
  Col,
  Card,
  CardHeader
} from "shards-react";

import LoginForm from "../components/utils/LoginForm";

import { useIsAuthenticated } from "@azure/msal-react";


const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  useEffect(()=>{
    // console.log('IS AUTHENTICATED', isAuthenticated);
    if (isAuthenticated) {
       history.push('/saml');
    }
  },[isAuthenticated]);


  return (
  <div>
    <Container fluid className="m-auto pt-5 w-50">
        <Col>
          {/* Complete Form Example */}
          <Card small>
            <CardHeader className="">
              <h6 className="m-auto text-center">Login</h6>
            </CardHeader>
            <LoginForm />
          </Card>
        </Col>
    </Container>
  </div>)
};

export default Login;
