import React from "react";
import { Container, Card, CardBody, Button } from "shards-react";

const NewsDetails = ({
  postDetails,
  goBack
}) => {

  return (
    <Container>
      <Button className="btn-success mt-4" onClick={() => goBack()}>Torna indietro</Button>
      {postDetails &&
        <Card small className="p-4 mt-2 card-post card-post--aside card-post--1 big-news-container news-details-container">
            {postDetails.images && postDetails.images[0] &&
              <div
                className="card-post__image news-detail-post-image-container"
              >
                <img className="news-detail-image" src={postDetails.images[0].bytes ? `data:image/png;base64,${postDetails.images[0].bytes}` : null } alt={postDetails.images[0].alt}/>
              </div>
            }
            <CardBody className={'pt-3 ' + (postDetails.images && postDetails.images[0] ? 'pl-0' : '')}>
              <h5 className="card-title">
                {postDetails.title}
              </h5>
              <div>
                <p className="card-text d-inline-block mb-3">{postDetails.text}</p>
              </div>
              <span className="text-muted">{new Date(postDetails.date).toLocaleDateString()}</span>
            </CardBody>
        </Card>
      }
    </Container>
  );
}

export default NewsDetails;
