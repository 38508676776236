import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

let _store = {
  menuVisible: false,
  reloadMenu: false,
  reloadNotifies: false,
  navItems: getSidebarNavItems(),
  showLoader: false,
  showAlertPopup: null
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.UPDATE_MENU:
        this.reloadMenu();
        break;
      case Constants.UPDATE_NOTIFIES:
        this.reloadNotifies();
        break;
      case Constants.TOGGLE_ALERT_POPUP:
        this.toggleAlertPopup(payload.message);
        break;
      case Constants.SHOW_LOADER:
        this.showLoader();
        break;
      case Constants.HIDE_LOADER:
        this.hideLoader();
        break;
      default:
    }
  }

  // ------------------------------------- METHODS TO HANDLE SOCKET EVENTS -------------------------------------
  /**
   * RELOAD MENU
   */
  reloadMenu() {
    _store.reloadMenu = true;
    this.emit(Constants.CHANGE_MENU);
  }

  getReloadMenu() {
    return _store.reloadMenu;
  }

  reloadMenuCompleted() {
    _store.reloadMenu = false;
  }

  addMenuChangeListener(callback) {
    this.on(Constants.CHANGE_MENU, callback);
  }

  removeMenuChangeListener(callback) {
    this.removeListener(Constants.CHANGE_MENU, callback);
  }

  /**
   * RELOAD NOTIFIES
   */
  reloadNotifies() {
    this.emit(Constants.CHANGE_NOTIFIES);
  }

  getReloadNotifies() {
    return _store.reloadNotifies;
  }

  reloadNotifiesCompleted() {
    _store.reloadNotifies = false;
  }

  addNotifiesChangeListener(callback) {
    this.on(Constants.CHANGE_NOTIFIES, callback);
  }

  removeNotifiesChangeListener(callback) {
    this.removeListener(Constants.CHANGE_NOTIFIES, callback);
  }

  // ------------------------------------- END METHODS TO HANDLE SOCKET EVENTS -------------------------------------

  addChangeLoaderListener(callback) {
    this.on(Constants.CHANGE_LOADER, callback);
  }

  removeChangeLoaderListener(callback) {
    this.removeListener(Constants.CHANGE_LOADER, callback);
  }

  showLoader() {
    _store.showLoader = true;
    this.emit(Constants.CHANGE_LOADER);
  }

  hideLoader() {
    _store.showLoader = false;
    this.emit(Constants.CHANGE_LOADER);
  }

  getShowLoader() {
    return _store.showLoader;
  }

  addChangeAlertPopupVisibilityListener(callback) {
    this.on(Constants.CHANGE_ALERT_POPUP, callback);
  }

  removeChangeAlertPopupVisibilityListener(callback) {
    this.removeListener(Constants.CHANGE_ALERT_POPUP, callback);
  }

  toggleAlertPopup(message) {
    _store.showAlertPopup = message;
    this.emit(Constants.CHANGE_ALERT_POPUP);
  }

  getShowAlertPopup() {
    return _store.showAlertPopup;
  }

  // viene attivato dall'azione "TOGGLE_SIDEBAR" tramite il dispatch
  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
