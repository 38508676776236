import { Constants, Dispatcher } from "../flux";

const showLoader = () => {
  Dispatcher.dispatch({
    actionType: Constants.SHOW_LOADER
  });
}

const hideLoader = () => {
  Dispatcher.dispatch({
    actionType: Constants.HIDE_LOADER
  });
}
export const ToggleLoaderService = {
  showLoader,
  hideLoader
}

export default ToggleLoaderService;
