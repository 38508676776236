import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import UserProfileLite from "./views/UserProfileLite";
import Errors from "./views/Errors";
import CompleteFormExample from "./components/utils/CompleteFormExample";

// import News from "./views/News";
import Login from "./views/login";
import Workflow from "./views/Workflows/Workflows";
import TemplateForm from "./views/RenderView/RenderTemplateForm";
import ComponentsOverview from "./views/ComponentsOverview";
import RenderView from "./views/RenderView/RenderView";
import RenderHTMLTemplate from "./views/RenderView/RenderHTMLTemplate";
import NewsDetails from "./views/News/NewsDetails";
import TaskCompleted from "./views/TaskCompleted";
import TaskPage from "./views/Workflows/TaskPage";
import AddNews from "./views/News/AddNews";
import { constants } from "./config/config";
import AssertionPage from "./components/common/AssertionPage";
import { ProfileContent } from "./components/utils/ProfileContent";

export default {
  guardedRoutes:
  [
    {
      path: "/user-profile",
      layout: DefaultLayout,
      component: UserProfileLite
    },
    {
      path: "/errors",
      layout: DefaultLayout,
      component: Errors
    },
    {
      path: "/wizard",
      layout: DefaultLayout,
      component: CompleteFormExample
    },
    {
      path: "/renderTemplate/:id",
      layout: DefaultLayout,
      component: RenderView
    },
    {
      path: "/workflows/status/:processKey",
      layout: DefaultLayout,
      component: Workflow
    },
    {
      path: "/task/:processKey",
      layout: DefaultLayout,
      component: TaskPage
    },
    {
      path: "/task-completed/:processKey",
      layout: DefaultLayout,
      component: TaskCompleted
    },
    {
      path: "/wizard/:id",
      layout: DefaultLayout,
      component: TemplateForm
    },
    {
      path: "/renderView/:id",
      layout: DefaultLayout,
      component: RenderView
    },
    {
      path: "/news/:id",
      layout: DefaultLayout,
      component: NewsDetails
    },
    {
      path: "/addNews",
      layout: DefaultLayout,
      component: AddNews
    },
    {
      path: "/assertionPage",
      layout: DefaultLayout,
      component: AssertionPage
    },
    {
      path: "/renderContentRef",
      layout: DefaultLayout,
      component: RenderHTMLTemplate
    },
    {
      path: "/components",
      layout: DefaultLayout,
      component: ComponentsOverview
    },
    {
      path: "/*",
      layout: DefaultLayout,
      component: () => <Redirect to={"/renderView/" + constants.newsId} push/>
    },
  ]
  ,
  noGuardedRoutes: [
    {
      path: "/login",
      exact: true,
      layout: DefaultLayout,
      component: Login
    },
    {
      path: "/saml",
      exact: true,
      layout: DefaultLayout,
      component: ProfileContent
    },
    {
      path: "/*",
      layout: DefaultLayout,
      component: () => <Redirect to={"/login"} />
    }
  ]
};
