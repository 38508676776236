export default {
  CHANGE: "CHANGE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  UPDATE_MENU: "UPDATE_MENU",
  CHANGE_MENU: "CHANGE_MENU",
  UPDATE_NOTIFIES: "UPDATE_NOTIFIES",
  CHANGE_NOTIFIES: "CHANGE_NOTIFIES",
  CHANGE_LOADER: "CHANGE_LOADER",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  TOGGLE_ALERT_POPUP: "TOGGLE_ALERT_POPUP",
  CHANGE_ALERT_POPUP: "CHANGE_ALERT_POPUP"
};
