import request from "./base/baseService";
import { constants } from "../config/config";
import axios from 'axios';

const url = `${constants.api.url}`;
const workflowStatusRoute = constants.api_routes.workflows_status;
const workflowStartRoute = constants.api_routes.workflows_start;
const workflowsDeleteProcessRoute = constants.api_routes.workflows_delete_process;
const contentRoute = constants.api_routes.content;
const workflowSubmitRoute = constants.api_routes.workflows_form;
const workflowReassignRoute = constants.api_routes.workflows_reassign;
const workflowUploadFilesRoute = constants.api_routes.workflows_upload_files;
const workflowGetFilesRoute = constants.api_routes.workflows_get_files;
const workflowsGetFile = constants.api_routes.workflows_get_file;
const workflowsRemoveFile = constants.api_routes.workflows_remove_file;

const getStatus = async (processKey) => {
  return await request({
    url: `${url}${workflowStatusRoute.url}/${processKey}`,
    method: workflowStatusRoute.method
  });
}

const startNewProcess = async (processKey) => {
  return await request({
    url: `${url}${workflowStartRoute.url}/${processKey}`,
    method: workflowStartRoute.method
  });
}

const deleteProcess = async (processId) => {
  const replacedUrl = workflowsDeleteProcessRoute.url.replace("<UUID_del_processo>", processId);
  return await request({
    url: `${url}${replacedUrl}`,
    method: workflowsDeleteProcessRoute.method
  });
}

const getTaskForm = async (contentRef) => {
  return await request({
    url: `${url}${contentRoute.url}`,
    method: contentRoute.method,
    data: contentRef
  });
}

const submitForm = async (submitUrl, formData = null) => {
  return await request({
    url: `${url}${submitUrl}`,
    method: workflowSubmitRoute.method,
    data: formData
  })
}

const reassign = async (formData) => {
  return await request({
    url: `${url}${workflowReassignRoute.url}`,
    method: workflowReassignRoute.method,
    data: formData
  });
}

const uploadFiles = async (files) => {
  return await axios.post(`${url}${workflowUploadFilesRoute.url}`, files, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + localStorage.getItem('token')
    },
  })
}

const getFiles = async (processId, variableId) => {
  const replacedUrl = workflowGetFilesRoute.url.replace("<UUID_del_processo>", processId).replace("<id_variable>", variableId);
  return await request({
    url: `${url}${replacedUrl}`,
    method: workflowGetFilesRoute.method
  });
}

const getFile = async (fileId) => {
  const replacedUrl = workflowsGetFile.url.replace("<UUID_del_file>", fileId);
  return await request({
    url: `${url}${replacedUrl}`,
    method: workflowsGetFile.method,
    responseType: 'blob'
  });
}

const removeFile = async (fileId) => {
  const replacedUrl = workflowsRemoveFile.url.replace("<UUID_del_file>", fileId);
  return await request({
    url: `${url}${replacedUrl}`,
    method: workflowsRemoveFile.method,
  });
}

export const WorkflowsService = {
  getStatus,
  startNewProcess,
  deleteProcess,
  getTaskForm,
  submitForm,
  reassign,
  uploadFiles,
  getFiles,
  getFile,
  removeFile
}

export default WorkflowsService;
