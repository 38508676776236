/* eslint-disable */
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "shards-react";

const ConfirmModal = ({
  dataToShow,
  show,
  handleClose
}) => {

  const closeModal = (confirmed) => {
    handleClose(confirmed);
  }

  return (
    <Modal open={show} hideModal={handleClose}>
    { dataToShow &&
      <>
        <ModalHeader>
          {dataToShow.title}
          <span className="float-right cursor-pointer" onClick={() => closeModal(false)}>x</span>
        </ModalHeader>
        <ModalBody>
          {dataToShow.body}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex pt-2">
            <Button className="btn btn-light border-warning text-warning mr-1" onClick={() => closeModal(false)}>Annulla</Button>
            <Button className="btn-success" onClick={() => closeModal(true)}>Conferma</Button>
          </div>
        </ModalFooter>
      </>
    }
    </Modal>
  );

};

export default ConfirmModal;
