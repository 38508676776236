/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { NavItem, NavLink } from "shards-react";
import CollapsableNavItem from "./CollapsableNavItem";
import { NavLink as RouteNavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";

const SidebarNavItem = ({ item, menuVisible }) => {

  const menuItem = <div className={" menu-item-container"}>
    {/* DEFAULT ICON */}
    {menuVisible &&
    <>
      {!item.icon && (
        <i className="material-icons">view_module</i>
      )}
      {item.icon &&
        <img className="menu-icon" src={`data:${item.icon.mimeType};base64,${item.icon.bytes}`}
        alt={item.icon.alt}
        height={item.icon.hsize}
        width={item.icon.vsize}
        />
      }
    </>
    }
    {/* {menuVisible && */}
      <div className={"menu-item-text-container " + (!menuVisible ? "closed-menu-item-text-container" : "")}>
        {item.itemLabel && <p>{item.itemLabel}</p>}
        {menuVisible && item.itemDescription && <p>{item.itemDescription}</p>}
      </div>
    {/* } */}
  </div>;

  return (
  <NavItem>
    {item && <>
        {item.type != 'SUBMENU' &&
          <NavLink className={!menuVisible ? "p-0" : ''} tag={(props) => <RouteNavLink {...props} />} to={item.urlRef ? item.urlRef : `/renderView/${item.contentRef}`}>
            <Tooltip title={!menuVisible ? (item.itemLabel + ' - ' + item.itemDescription) : null} placement={'right-end'} arrow className={!menuVisible ? "p-3" : ''}>
              {menuItem}
            </Tooltip>
          </NavLink>
        }
        {item.type == 'SUBMENU' &&
          <NavLink className={"p-0"}>
            {menuVisible &&
              <CollapsableNavItem accordionSummary={menuItem} menuItemId={item.itemId}>
              </CollapsableNavItem>
            }
            {!menuVisible &&
              <Tooltip title={item.itemLabel + ' - ' + item.itemDescription} placement={'right-end'} arrow className="p-3">
                {menuItem}
              </Tooltip>
            }
          </NavLink>
        }
      </>
    }
  </NavItem>
  )
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
