import React from "react";
import { Row, Col, Card, CardBody, CardHeader, FormInput, Button } from "shards-react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
/* eslint-disable */

const WorkflowTable = ({
  tableTitle,
  dataToShow,
  columns,
  isConcludedList,
  processKey
}) => {
  let history = useHistory();
  const [tableData, setTableData] = useState();
  const [actualSorting, setActualSorting] = useState({
    sortingType: '',
    sortingProperty: ''
  });
  const [searchInputValue, setSearchInputValue] = useState();


  useEffect(() => {
    setTableData(dataToShow);
  }, [dataToShow]);

  const goToTicket = (contentRef) => {
    history.push('/task/' + processKey, {
      contentRef
    });
  }

  const sortDataByColumn = (sortingType, column) => {
    const res = [...tableData];

    res.sort((a, b) => {
      let valueA = '';
      let valueB = '';

      if ((a.data[column].isHtml && b.data[column].isHtml) || (a.data[column].isDate && b.data[column].isDate)) {
        valueA = a.data[column].isDate ? new Date(a.data[column].realValue).valueOf() : (a.data[column].realValue).toUpperCase();
        valueB = b.data[column].isDate ? new Date(b.data[column].realValue).valueOf() : (b.data[column].realValue).toUpperCase();
      } else {
        valueA = (a.data[column].dataToDisplay).toUpperCase();
        valueB = (b.data[column].dataToDisplay).toUpperCase();
      }

      if (sortingType === 'UP') {
        if (valueA < valueB) {
          return 1;
        }
        if (valueA > valueB) {
          return -1;
        }
        return 0;
      } else {
        if (valueA > valueB) {
          return 1;
        }
        if (valueA < valueB) {
          return -1;
        }
        return 0;
      }
    });

    setActualSorting({sortingType, sortingProperty: column})
    setTableData(res);
  }

  const searchText = () => {
    if (searchInputValue) {
      const res = [...dataToShow].reduce((acc, current) => {
        let foundItem = false;

        // Iterate for row properties
        for (const key in current.data) {
          const currentItem = current.data[key];

          if (!foundItem) {
            let itemToCheck = JSON.stringify(currentItem.dataToDisplay);

            if (currentItem.isHtml) {
              itemToCheck = currentItem.realValue;
            }

            const uppercasedItem = (itemToCheck).toUpperCase();
            if (uppercasedItem.indexOf(searchInputValue.toUpperCase()) != -1) {
              foundItem = true;
            }
          }
        }

        if (foundItem) {
          acc.push(current);
        }
        return acc;
      }, []);

      setTableData(res);
    } else {
      setTableData(dataToShow);
    }
  }

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className={`p-2 border-bottom process-table-header process-${isConcludedList ? 'concluded' : 'active' }-list-title`}>
            <h6 className="m-0 text-white">{tableTitle}</h6>
            <div className="process-table-search-container">
              <FormInput
                className={"form-control"}
                type="text"
                placeholder={"Cerca nella tabella..."}
                onChange={(e) => setSearchInputValue(e.target.value)}
              />
              <Button className="search-button" onClick={() => searchText()}> Cerca </Button>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <table className="table mb-0 workflow-table">
              <thead className="bg-light">
                <tr>
                  {columns && columns.map((item, idx) => (
                    <th scope="col" className={(item.className ? item.className : '') + " border-0"} key={item.title + idx}>
                      <div className={item.isSortable ? 'sortable-col' : ''}>
                        {item.title}
                        {item.isSortable &&
                          <div>
                            <div
                              className={"sort-icon sort-up-icon " + (actualSorting.sortingType === 'UP' && actualSorting.sortingProperty === item.propertyName ? 'sort-icon-active' : 'sort-icon-disable')}
                              onClick={() => sortDataByColumn('UP', item.propertyName)}>
                            </div>
                            <div
                              className={"sort-icon sort-down-icon " + (actualSorting.sortingType === 'DOWN' && actualSorting.sortingProperty === item.propertyName ? 'sort-icon-active' : 'sort-icon-disable')}
                              onClick={() => sortDataByColumn('DOWN', item.propertyName)}>
                            </div>
                          </div>
                        }
                      </div>
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(!tableData || tableData.length === 0) && <tr className="text-center">
                  <td>Non ci sono elementi da mostrare.</td>
                </tr>}
                {tableData && tableData.length > 0 &&
                  tableData.map(process =>{
                    return <tr onClick={(e) => isConcludedList ? null : goToTicket(process.contentRef)} key={process.data.taskId && process.data.taskId.dataToDisplay ? process.data.taskId.dataToDisplay : process.data.id.realValue} className={isConcludedList ? 'cursor-default' : null}>
                      {Object.values(process.data).map((processDetail, idx) =>
                        <td className={processDetail.className} key={processDetail.dataToDisplay + idx}>{processDetail.dataToDisplay}</td>
                      )}
                    </tr>}
                  )
                }
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

};

export default WorkflowTable;
