/* eslint-disable */
import axios from 'axios';
import md5 from "md5";
import { constants } from '../config/config';
import { toHex } from 'hex-my-bytes'
import request from "./base/baseService";
// import { Constants, Dispatcher } from '../flux';
import HandleErrorService from './handleErrorService';

const url = constants.api.url;
const serverUrl = constants.api.url_out;
const logoffRoute = constants.api_routes.logoff;
const loginSSORoute = constants.api_routes.loginSSO;

const login = async (username, password, digestHeaders = null, firstTime = true) => {
  // HIDE ERROR ALERT
  HandleErrorService.hideErrorAlert();

  let config = {auth: {
    username:username,
    password: password
  }};

  if (digestHeaders) {
    config = {...digestHeaders};
  }

  const data = await axios
    .get(`${constants.api.url_out}${constants.api_routes.auth.url}`, {headers: digestHeaders},
        config
    ).catch(async (err) => {
      // PERFORM DIGEST AUTH REQUEST: THE FIRST REQUEST ALWAYS RETURN 401
      if (err.response && err.response.status == 401 && firstTime) {
        const digestParams = err.response.headers['www-authenticate'].replace('Digest', '').split(',').map(item => item.trim());
        const realm = digestParams[0].replace('realm=', '').replaceAll('"', '');
        const algorithm = digestParams[2].replace('algorithm=', '');
        const nonce = digestParams[3].replace('nonce=', '').replaceAll('"', '');
        const opaque = digestParams[4].replace('opaque=', '').replaceAll('"', '');

        const HA1 = md5((username + ':' + realm + ':' + password), {asBytes: true});
        const HA2 = md5(('GET:/auth'), {asBytes: true});

        const HA1Hex = toHex(HA1);
        const HA2Hex = toHex(HA2);

        const response = md5((HA1Hex + ':' + nonce + ':' + HA2Hex), {asBytes: true});
        const resHex = toHex(response);

        const digestHeaders = {
          'Authorization':
            'Digest opaque=' + opaque + ', response=' + resHex + ', realm=' + realm + ', uri=/auth, username=' + username + ', algorithm=' + algorithm + ', nonce=' + nonce
        };

        return await login(username, password, digestHeaders, false);
      } else {
        // SHOW ERROR
        HandleErrorService.showErrorAlert(err.response && err.response.data.status && err.response.data.error ? err.response.data.status + ' ' + err.response.data.error : JSON.stringify(err));
      }
    });

    return data;
}

const logoff = async () => {
  return await request({
    url: `${url}${logoffRoute.url}`,
    method: logoffRoute.method
  })
}

const loginSSO = async (body) => {
  // let data = await request({
  //   url: `${serverUrl}${loginSSORoute.url}`,
  //   method: loginSSORoute.method,
  //   data: body
  // });
  // localStorage.clear();

  let  data = await axios.post(`${serverUrl}${loginSSORoute.url}`, body);
  localStorage.clear();
  return data;
}
export const LoginService = {
  login,
  logoff,
  loginSSO
}

export default LoginService;
