import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import GaugeChart from 'react-gauge-chart';

class RenderIndicator extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  calculatePercentage(data) {
    return (data.value - data.minValue) / (data.maxValue - data.minValue);
  }

  formatPercentValue(data) {
    if (data.percent) {
      return this.calculatePercentage(data);
    } else {
      return (data.value - data.minValue) / data.maxValue;
    }
  }

  formatTextValue(data) {
    if (data.percent) {
      return (this.calculatePercentage(data) * 100).toFixed(2);
    } else {
      return data.value;
    }
  }

  render() {
    const colors = [
      'rgb(235 97 26)',
      'rgb(241 172 35)',
      'rgb(249 219 53)',
      'rgb(188 193 29)',
      'rgb(149 193 29)'
    ]

    const data = this.props.data;

    return (
      <div className="gauge-chart-container">
        <GaugeChart id={`gauge-chart-${shortid()}`}
          animate={false}
          nrOfLevels={5}
          percent={this.formatPercentValue(data)}
          textColor={'#000'}
          fontSize={20}
          colors={[...colors]}
          needleColor={'rgb(238 114 25)'}
          needleBaseColor={'rgb(238 114 25)'}
          formatTextValue={(value) => (data.formattedValue ? data.formattedValue : (this.formatTextValue(data) + (data.percent ? ' %' : '')))}
          arcWidth={0.3}
        >
        </GaugeChart>
        <div className="gauge-chart-info">
          <p>{data.formattedMinValue ? data.formattedMinValue : data.minValue}</p>
          <p>{data.formattedMaxValue ? data.formattedMaxValue : data.maxValue}</p>
        </div>
      </div>
    );
  }
}

RenderIndicator.propTypes = {
  /**
   * The chart data.
   */
  data: PropTypes.object.isRequired,
};

RenderIndicator.defaultProps = {
  data: {}
};

export default RenderIndicator;
