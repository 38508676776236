import { Constants, Dispatcher } from "../flux";

const showErrorAlert = (err) => {
  Dispatcher.dispatch({
    actionType: Constants.TOGGLE_ALERT_POPUP,
    payload: {
      message: err
    }
  });
}

const hideErrorAlert = () => {
  Dispatcher.dispatch({
    actionType: Constants.TOGGLE_ALERT_POPUP,
    payload: {
      message: null
    }
  });

}

export const HandleErrorService = {
  showErrorAlert,
  hideErrorAlert
}

export default HandleErrorService;
