/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { withRouter } from 'react-router';

import NewsService from "../../service/newsService";
import NewsList from "./NewsList";
import NewsDetails from "./NewsDetails";
import { ListGroup, ListGroupItem } from "shards-react";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: this.props.news,
      pageNumber: 0,
      newsDetails: null
    };

    this.goToNewsDetailPage = this.goToNewsDetailPage.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  async loadNews() {
    this.setState({
      ...this.state,
      pageNumber: this.state.pageNumber + 1,
      news: this.state.news.concat(await this.getNews())
    });
  }

  async getNews() {
    const result = await NewsService.getNews(this.state.pageNumber);
    return result.content.items;
  }

  goToNewsDetailPage(post) {
    this.setState({
      ...this.state,
      newsDetails: post
    });
  }

  goBack() {
    this.setState({
      ...this.state,
      newsDetails: null
    });
  }

  render() {
    const {
      news,
      newsDetails
    } = this.state;

    const {
      canManage
    } = this.props;

    return (<>
      {!newsDetails && <ListGroup className="m-4">
        <ListGroupItem>
          <NewsList news={news} goToDetailsPage={this.goToNewsDetailPage} canManage={canManage}></NewsList>
        </ListGroupItem>
      </ListGroup>
      }
      {newsDetails && <NewsDetails postDetails={newsDetails} goBack={this.goBack}></NewsDetails>}
    </>
    );
  }
}

export default withRouter(News);
