import React from "react";
import { NavItem, NavLink, Badge, Collapse } from "shards-react";
import { Store } from "../../../../flux"
import NotificationsService from "../../../../service/notificationsService";
import NotificationGroup from "./NotificationGroup";
export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      notifications: {},
      badgeCount: null
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    Store.addNotifiesChangeListener(this.onChange);
    await this.getNotifications();
  }

  componentWillUnmount() {
    Store.removeNotifiesChangeListener(this.onChange);
  }

  toggleNotifications() {
    this.setState({
      visible: !this.state.visible
    });
  }

  async onChange() {
    await this.getNotifications();
  }

  async getNotifications() {
    const notificationsRes = await NotificationsService.getNotifications();

    if (notificationsRes && notificationsRes.status === 200) {
      const notifications = notificationsRes.notifies.reduce((acc, item) => {
        if (acc[item.category]) {
          acc[item.category].push(item);
        } else {
          acc[item.category] = [item];
        }
        return acc;
      }, {});

      this.setState({
        ...this.state,
        notifications: notifications,
        badgeCount: notificationsRes.notifies.length
      });
    }
  }

  render() {
    const { notifications, badgeCount } = this.state;
    return (
      <NavItem className="border-right dropdown notifications">
        <NavLink
          className="nav-link-icon text-center cursor-pointer"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            {badgeCount && <Badge pill theme="danger">
              {badgeCount}
            </Badge>}
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small notifications-container"
        >
          {notifications && Object.keys(notifications).map((prop, idx) =>
            <NotificationGroup key={idx} notificationsGroup={notifications[prop]} category={prop}></NotificationGroup>
          )}
        </Collapse>
      </NavItem>
    );
  }
}
