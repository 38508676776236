import request from "./base/baseService";
import { constants } from "../config/config";

let url = `${constants.api.url}`;
let menuRoute = constants.api_routes.menu;

const getMenu = async (idMenu = -1) => {
    const iterationId = 111111111111111111;
    return await request({
      url: `${url}${menuRoute.url}`,
      data: {
        iterationId,
        itemId: idMenu,
        followRef: false
      },
      method: menuRoute.method
    });
}


export const MenuService = {
  getMenu
}

export default MenuService;
