/* eslint-disable */
import React from "react";
import LoginService from "../../service/loginService";
import {useRef} from 'react';
import { SignInButton } from "./SignInButton";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  Button,
  FormFeedback
} from "shards-react";
import jwtDecode from "jwt-decode";
import { useState } from "react";

const LoginForm = () => {
    const username = useRef(null);
    const password = useRef(null);

    const [invalidFields, setInvalidFields] = useState({
      username: false,
      password: false
    });

    const [loginErrorMessage, setLoginErrorMessage] = useState();

    const handleSubmit = async () => {
      const loggedData = await LoginService.login(username.current.value,password.current.value);

      if (loggedData && loggedData.data) {
        if (loggedData.data.status == 400) {
          // TODO: SHOW ERROR UNDER INPUTS
          // window.alert(loggedData.data.status_desc);
          setLoginErrorMessage(loggedData.data.status_desc);
          setInvalidFields({
            username: true,
            password: true
          })
        } else {
          localStorage.setItem('token', loggedData.data.jwt);
          localStorage.setItem('username', jwtDecode(loggedData.data.jwt).name);
          window.location.reload();
        }
      }
    };

    return (
      <ListGroup flush className='w-75 mr-auto ml-auto'>
        <ListGroupItem className='p-0'>
          <Row>
            <Col>
              <Form form onSubmit={handleSubmit} autoComplete= "chrome-off">
                  <FormGroup className="w-50 m-auto" >
                    <label htmlFor="feEmailAddress">Username</label>
                    <FormInput
                      innerRef={username}
                      id="feEmailAddress"
                      type="text"
                      name="uname"
                      placeholder="Username"
                      className="mb-4"
                      invalid={invalidFields.username}
                    />
                  </FormGroup>
                  <FormGroup className="w-50 m-auto">
                    <label htmlFor="fePassword">Password</label>
                    <FormInput
                      innerRef={password}
                      id="fePassword"
                      type="password"
                      name="pass"
                      placeholder="Password"
                      invalid={invalidFields.password}
                    />
                    {loginErrorMessage && <FormFeedback>{loginErrorMessage}</FormFeedback>}
                  </FormGroup>
                  <Row className='mt-4 mb-4 ml-auto mr-auto'>
                    <Button className="ml-auto mr-auto btn-success" type="button" onClick={async () => await handleSubmit()}>LOGIN</Button>
                    <SignInButton />
                  </Row>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
};

export default LoginForm;
