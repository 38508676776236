/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import {
  Container,
  Row,
  // Col,
  // Card,
  // CardBody,
  // Button
} from "shards-react";
import { withRouter } from 'react-router';

import PageTitle from "../../components/common/PageTitle";
import ToggleLoaderService from "../../service/toggleLoaderService";
import NewsMainCardPost from "./NewsMainCardPost";
import NewsLittleCardPost from "./NewsLittleCardPost";


class NewsList extends React.Component {
  constructor(props) {
    super(props);
    ToggleLoaderService.hideLoader();
  }

  goToNewsDetailPage(post) {
    this.props.goToDetailsPage(post);
  }

  goToAddNews() {
    return this.props.history.push('/addNews', {renderViewId: this.props.match.params.id});
  }

  render() {
    const {
      news,
      // canManage
    } = this.props;
    return (
      <Container fluid className="main-content-container news-list-container" >
        {/* Page Header */}
        {/* {canManage && <Button className="btn-success" onClick={() => this.goToAddNews()}>Aggiungi Notizia</Button>} */}
        <Row noGutters className="page-header py-4 justify-content-between align-items-center">
          <PageTitle sm="4" title="News" className="text-sm-left text-uppercase" />
          <div className="d-flex align-items-end">
            <label>Ordina per</label>
            <select
            id={'order-by-select'}
            value="data"
            className="ml-3 form-control news-select"
            >
              <option value="data">Data</option>
            </select>
          </div>
        </Row>

        <Row>
          {news && news.length > 0 &&  news.map((post, idx) =>
            <>
              {post.main && <NewsMainCardPost post={post} key={idx}/>}
              {!post.main && <NewsLittleCardPost post={post} key={idx}/>}
            </>
            // eslint-disable-next-line
            // if (idx % 6 == 4 || idx % 6 == 5) {
            //   return <Col lg="6" sm="12" className="mb-4 cursor-pointer" key={idx} onClick={() => this.goToNewsDetailPage(post)}>
            //   <Card small className="card-post card-post--aside card-post--1 big-news-container">
            //     {post.images && post.images[0] &&
            //       <div
            //         className="card-post__image news-list-card-post-image"
            //       >
            //         <img className="news-list-image" src={post.images[0].bytes ? `data:image/png;base64,${post.images[0].bytes}` : null } alt={post.images[0].alt}/>
            //       </div>
            //     }
            //     <CardBody>
            //       <h5 className="card-title">
            //         <a className="text-fiord-blue" href="#">
            //           {post.title}
            //         </a>
            //       </h5>
            //       <div className="news-body-container">
            //         <p className="card-text d-inline-block mb-3">{post.text}</p>
            //       </div>
            //       <span className="text-muted">{new Date(post.date).toLocaleDateString()}</span>
            //     </CardBody>
            //   </Card>
            // </Col>
            // } else {
              // return <Col md={post.main ? "12" : "4"} sm="12" className="mb-4 cursor-pointer" key={idx}
              // // onClick={() => this.goToNewsDetailPage(post)}
              // >
              //     <Card small className={"card-post card-post--1" + (post.main ? " card-post--aside big-news-container" : " little-news-container")}>
              //       {post.images && post.images[0] &&
              //         <div
              //           className="card-post__image news-list-card-post-image"
              //         >
              //           <img className="news-list-image" src={post.images[0].bytes ? `data:image/png;base64,${post.images[0].bytes}` : require('../../images/' + post.images[0].filename)} alt={post.images[0].alt}/>
              //         </div>

              //       }
              //       <CardBody>
              //         <h5 className="card-title">
              //           <a href="#" className="text-fiord-blue">
              //             {post.title}
              //           </a>
              //         </h5>
              //         {post.text &&
              //           <div className="news-body-container">
              //             <p className="card-text d-inline-block mb-3">{post.text}</p>
              //           </div>
              //         }
              //         <span className="text-muted">{new Date(post.date).toLocaleDateString()}</span>
              //       </CardBody>
              //     </Card>
              //   </Col>
              // // }
          )}
          <div className="d-flex m-auto pagination-container">
            <div className="mr-4 pagination-button-disabled"> {'<'} </div>
            <div className="mr-4 pagination-button-active"> 1 </div>
            <div className="mr-4"> 2 </div>
            <div className="mr-4"> 3 </div>
            <div> {'>'} </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default withRouter(NewsList);
