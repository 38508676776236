const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://10.254.3.39:8081';
const fe_base_url = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';
const clientId = process.env.AZURESSO_CLIENTID || '243ab3cf-d1f0-4901-817a-472b1d635567';
const tenantId = process.env.AZURESSO_TENANTID || 'ae46a7d9-1f65-4538-a730-a5c794b74bf8';
export const constants = {
  defaultErrorMessage: 'Si è verificato un errore. Si prega di riprovare più tardi.',
  newsId: '00000000-0000-0000-0000-500000000001',
  secret: "H+n6#y$0#c@",
  logoFileName: process.env.LOGO || 'revo-insurance-logo.svg',
  api: {
    url: `${serverUrl}/api/v1`,
    url_out: serverUrl
  },
  api_routes: {
    wsock: {
      url: '/services/wsock'
    },
    auth: {
      url: '/services/auth'
    },
    menu: {
      url: '/menu',
      method: 'POST'
    },
    content: {
      url: '/content',
      method: 'POST'
    },
    notification: {
      url: '/notifies',
      method: 'POST'
    },
    notification_touch: {
      url: '/notifies/touch',
      method: 'POST'
    },
    workflows_list: {
      url: '/workflows/list',
      method: 'GET'
    },
    workflows_status: {
      url: '/workflows/status',
      method: 'GET'
    },
    workflows_start: {
      url: '/workflows/start',
      method: 'POST'
    },
    workflows_delete_process: {
      url: '/workflows/delete/<UUID_del_processo>',
      method: 'DELETE'
    },
    workflows_form: {
      url: '/workflows/form/submit',
      method: 'POST'
    },
    workflows_reassign: {
      url: '/workflows/reassign',
      method: 'POST'
    },
    workflows_upload_files: {
      url: '/workflows/documents/upload',
      method: 'POST'
    },
    workflows_get_files: {
      url: '/workflows/documents/list/<UUID_del_processo>/<id_variable>',
      method: 'GET'
    },
    workflows_get_file: {
      url: '/workflows/documents/get/<UUID_del_file>',
      method: 'GET'
    },
    workflows_remove_file: {
      url: '/workflows/documents/remove/<UUID_del_file>',
      method: 'DELETE'
    },
    wizards_list: {
      url: '/wizards/list',
      method: 'GET'
    },
    wizards_status: {
      url: '/wizards/status',
      method: 'GET'
    },
    wizards_start: {
      url: '/wizards/start',
      method: 'POST'
    },
    wizards_form: {
      url: '/wizards/form/submit',
      method: 'POST'
    },
    media_download: {
      url: '/media/download',
      method: 'GET'
    },
    media_replace: {
      url: '/media/replace',
      method: 'GET'
    },
    media_upload: {
      url: '/media/upload',
      method: 'POST'
    },
    media_remove: {
      url: '/media/remove',
      method: 'GET'
    },
    news_upload: {
      url: '/news/upload',
      method: 'POST'
    },
    news_replace: {
      url: '/news/replace',
      method: 'POST'
    },
    logoff: {
      url: '/logoff',
      method: 'GET'
    },
    loginSSO: {
      url: "/services/azuresso",
      method: "POST"
    }
  },
  componentsWithoutSpinnerLoader: {
    login: 'login',
    task: 'task',
    addNews: 'addNews',
    'task-completed': 'task-completed'
  },
  pageCategory: {
    HTML: 'html',
    CMS: 'cms',
    SUBMENU: 'submenu',
    PORTAL: 'portal'
  }
}
export const azuresso_msalConfig = {
  auth: {
    azuresso_clientId: clientId,
    //REVO Portal
    //   authority: "https://vpninlet-to-httc-infoedge.mywire.org/common.microsoft.com", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    azuresso_authority: `https://login.microsoftonline.com/${tenantId}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    azuresso_redirectUri: `${fe_base_url}/saml/`,
  },
  cache: {
    azuresso_cacheLocation: "sessionStorage", // This configures where your cache will be stored
    azuresso_storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const azuresso_loginRequest = {
  azuresso_scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const azuresso_graphConfig = {
  azuresso_graphMeEndpoint: "https://graph.microsoft.com"
};
