import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.1.1.0.min.css";
import "./styles/style.css";
import { useEffect } from "react";
import { useState } from "react";

// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const App = () => {

  const [actualRoutes, setActualRoutes] = useState([]);


  useEffect(() => {
    const actualRoutes = localStorage.getItem('token') ? routes.guardedRoutes : routes.noGuardedRoutes;
    setActualRoutes(actualRoutes);
  }, [localStorage.getItem('token')]);

  return (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <Switch>
      {actualRoutes && actualRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              let totalProps = {...props};
              if (route.props) {
                totalProps = {...route.props};
              }

              return (
                <route.layout {...totalProps}>
                  <route.component {...totalProps} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </Switch>
  </Router>)
}
export default App;
