import React, { useEffect } from "react";
import { Row, Col, Card, CardBody, CardHeader } from "shards-react";
import { useState } from "react";
import RenderIndicator from "../RenderView/RenderIndicator";
import RenderChart from "../RenderView/RenderChart";

/* eslint-disable */

const WorkflowDashbaord = ({
numberActiveProcesses,
concludedProcessList
}) => {
  const [numberConcludedProcesses, setNumberConcludedProcesses] = useState();
  const [totalTime, setTotalTime] = useState();
  const [moreExpensiveTasks, setMoreExpensiveTasks] = useState([]);

  useEffect(() => {
    const concludedProcessListLength = concludedProcessList.length;
    setNumberConcludedProcesses(concludedProcessListLength);

    let counter = 0;
    const tasksList = [];
    concludedProcessList.forEach(proc => {
      proc.tasks.forEach(task => {
        counter += task.duration;
          if (task.duration) {
            const found = tasksList.findIndex(x => (x.name).trim().toUpperCase() == (task.name).trim().toUpperCase());

            if (found != -1) {
              tasksList.splice(found, 1, {...tasksList[found], duration: task.duration + tasksList[found].duration, counter: tasksList[found].counter + 1})
            } else {
              tasksList.push({
                name: task.name,
                duration: task.duration,
                counter: 1
              });
            }
          }
      });
    });

    setTotalTime(counter);
    const res = tasksList.sort((a, b) => (b.duration/b.counter) - (a.duration/a.counter));

    setMoreExpensiveTasks(res.slice(0, 3));


  }, [concludedProcessList]);


  const formattedDuration = (millis) => {
    let res = '';

    const seconds = Math.floor((millis / 1000) % 60);
    const minutes = Math.floor((millis / (1000 * 60)) % 60);
    const hours = Math.floor((millis / (1000 * 60 * 60)) % 24);
    const days = Math.floor((millis / (1000 * 60 * 60 * 24)) )

    days.toString().padStart(2, "0");
    hours.toString().padStart(2, "0");
    minutes.toString().padStart(2, "0");
    seconds.toString().padStart(2, "0");

    if (days > 0) {
      res = days + (days == 1 ? ' giorno' : ' giorni');
    }

    if (hours > 0) {
      res += (res ? ' e ' : '') + hours + (hours == 1 ? ' ora' : ' ore');
    }

    if (minutes > 0 && !days) {
      res += (res ? ' e ' : '') + minutes + ' min.';
    }

    if (seconds > 0 && !hours) {
      res += (res ? ' e ' : '') + seconds + ' sec.';
    }

    return res;
  }

  const formattedDurationForGraphic = (millis) => {
    const time = (formattedDuration(millis)).split(' ');

    switch (time[1]) {
      case 'giorni':
        time.splice(1, 1, 'gg');
        break;

      default:
        break;
    }



    return time[0] + ' ' + time[1];
  }

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className={`border-bottom process-table-header workflow-dashboard-header p-2`}>
            <h6 className="m-0 text-white">Dashboard</h6>
          </CardHeader>
          <CardBody>
            {numberConcludedProcesses && totalTime &&
              <Row>
                <Col md={4}>
                  <h4 className="text-center">Processi conclusi</h4>
                  <RenderIndicator
                  data={{minValue: 0, value: numberConcludedProcesses, maxValue: numberActiveProcesses + numberConcludedProcesses}}
                  ></RenderIndicator>
                </Col>
                <Col md={4}>
                  <h4 className="text-center">Tempo medio</h4>
                  <RenderIndicator
                  data={{minValue: 0, value: totalTime/numberConcludedProcesses, maxValue: totalTime, formattedMaxValue: formattedDurationForGraphic(totalTime), formattedValue: formattedDuration(totalTime/numberConcludedProcesses)}}
                  ></RenderIndicator>
                </Col>
                {
                  moreExpensiveTasks &&
                  <Col md={4}>
                    <h4 className="text-center">Fasi dispendiose</h4>
                    <RenderChart
                    data={{legend: false, type: 'pie', serie: moreExpensiveTasks.map(x => {return {label: x.name, value: x.duration, formattedValue: formattedDuration(x.duration)}})}}
                    ></RenderChart>
                  </Col>
                }
              </Row>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

};

export default WorkflowDashbaord;
