/* eslint-disable */
import React, { useEffect, useState  } from "react";
import {
  Row,
  Col,
  FormInput,
  FormSelect,
  Container,
  DatePicker,
  Form,
  Button,
  FormTextarea
} from "shards-react";
import DownloadFilesSection from "../../components/common/DownloadFilesSection";

const RenderTemplateForm = ({
  formData,
  submit,
  formFields,
  onSubmit,
  uploadFiles,
  downloadedFiles,
  downloadFile,
  removeFile
}) => {
  const [formValues, setFormValues] = useState(formFields);
  const [uploadedFiles, setUploadedFiles] = useState();
  const [fileInput, setFileInput] = useState();

  useEffect(() => {
    setFormValues(formFields);

    return () => setFormValues({});
  }, [formFields]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleFilesUploaded = (event) => {
    setUploadedFiles(event.target.files);
  }

  const renderField = (field, index) => {

  switch (field.type) {
    case 'select':
      const optionSelectedValue = field.options.filter(op => op.selected);
      const selectValue = optionSelectedValue && optionSelectedValue.length > 0 ? optionSelectedValue[0].value : '';

      return <FormSelect
        id={'id-' + field.name}
        key={index}
        name={field.name}
        value={field.readOnly ? selectValue : formValues[field.name]}
        className="form-control"
        onChange={(e) => handleInputChange(e)}>
          <option value={''}>Seleziona valore</option>
          {field.options && field.options.length > 0 && field.options.map((option, idx) =>
            <option key={option.value + 'idx'} value={option.value}>{option.label}</option>
          )}
        </FormSelect>
    case 'textarea':
      return <FormTextarea
        className="form-control"
        key={index}
        id={'id-' + field.name}
        placeholder={field.placeholder}
        required={field.required}
        value={!field.readOnly ? formValues[field.name] : field.value}
        name={field.name}
        readOnly={field.readOnly}
        onChange={(e) => handleInputChange(e)}
      />
    case 'downloader':
      return <DownloadFilesSection downloadFile={(id, fileName) => downloadFile(id, fileName)} downloadedFiles={downloadedFiles}></DownloadFilesSection>;
    case 'uploader':
      return <>
      <div className={"uplpader-container " + (downloadedFiles ? 'mb-2' : '')}>
        <FormInput
            className={"form-control"}
            id={'id-' + field.name}
            type="file"
            placeholder={field.placeholder}
            required={field.required}
            name={field.name}
            readOnly={field.readOnly}
            onChange={(e) => handleFilesUploaded(e)}
            key={fileInput}
            multiple
          />

          <Button className="btn btn-success" disabled={!uploadedFiles} onClick={() => {uploadFiles ? uploadFiles(uploadedFiles, field.name) : null; setUploadedFiles(null); setFileInput(null)}}>
            Invia file
          </Button>
      </div>
        { downloadedFiles &&
          <DownloadFilesSection
          downloadFile={(id, fileName) => downloadFile(id, fileName)}
          downloadedFiles={downloadedFiles}
          label={'File Caricati'}
          removeFile={(id) => removeFile(id)}></DownloadFilesSection>
        }
      </>
    default:
      return <>
      <FormInput
          className="form-control"
          key={index}
          id={'id-' + field.name}
          type={field.type}
          placeholder={field.placeholder}
          required={field.required}
          value={!field.readOnly ? formValues[field.name] : field.value}
          checked={!field.readOnly ? formValues[field.name] : field.value}
          name={field.name}
          readOnly={field.readOnly}
          onChange={(e) => handleInputChange(e)}
        />
      </>
  }
}

const submitFormWithUrl = async (submitUrl) => {
  submit(submitUrl, formValues);
}

const submitForm = async () => {
  submit(formValues);
}

const clearForm = () => {
  setFormValues({...formFields});
}

return (
  <Container className="p-0">
    {formData &&
      <Form
        onSubmit={async (e) => {e.preventDefault(); onSubmit ? onSubmit(e) : (formData.update_controller ? await submitFormWithUrl(formData.update_controller, e) : await submitForm(e))}}>
        {formData.title && <h4 className={"pb-2 " + (formData.subtitle ? '' : ' border-bottom')}>{formData.title}</h4>}
        {formData.subtitle && <h5 className="border-bottom pb-2">{formData.subtitle}</h5>}
        {formData.rows && formData.rows.length > 0 && formData.rows.map((row, index) =>
          <Row key={index}>
            {
              row.fields && row.fields.length > 0 && row.fields.map((field, fIdx) =>
                <Col md={field.size} className={(field.type == 'checkbox' ? "checkbox-container " : '') + "form-group"} key={fIdx + field.name}>
                  {/* PUT CHECKBOX BEFORE LABEL */}
                  {field.type == 'checkbox' && renderField(field, fIdx)}

                  <label htmlFor={'id-' + field.name}>{field.label}</label>
                  {field.type != 'checkbox' && renderField(field, fIdx)}
                </Col>
              )
            }
          </Row>
        )}
        {
          formData &&
          <div className="d-flex justify-content-between pt-2">
            <Button onClick={() => clearForm()} type="button" className="btn btn-light border-warning text-warning">{formData.cancel_caption}</Button>
            <Button className="btn-success" onClick={async () => !onSubmit ? (formData.update_controller ? await submitFormWithUrl(formData.update_controller) : await submitForm()) : null} type={onSubmit ? "submit" : "button"}>{formData.submit_caption}</Button>
          </div>
        }
      </Form>
    }
  </Container>
  )
};

export default RenderTemplateForm;
