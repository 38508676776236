import { Tooltip } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row, Container, CardBody, Card, Button, Alert } from "shards-react";
import { constants } from "../../config/config";
import HandleErrorService from "../../service/handleErrorService";
import WorkflowsService from "../../service/workflowsService";
import RenderTemplateForm from "../RenderView/RenderTemplateForm";
/* eslint-disable */
const TaskPage = () => {
  const {processKey} = useParams();
  const location = useLocation();
  const [taskForm, setTaskForm] = useState();
  const [taskFields, setTaskFields] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [downloaderName, setDownloaderName] = useState();
  const [downloadedFiles, setDownloadedFiles] = useState();
  const [successSentence, setSuccessSentece] = useState('Fase completata!');
  const [processInstance, setProcessInstance] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const { contentRef, templateForm } = location.state;
  const history = useHistory();

  useEffect(() => {
    const getTaskForm = async (contentRef) => {
      const tmpForm = await WorkflowsService.getTaskForm(contentRef);
      if (tmpForm && tmpForm.status == 200) {
        setProcessInstance(tmpForm.contents[0].process.instance);
        const handledForm = handleUploaderAndDownloaderItems(tmpForm.contents[0]);

        setTaskForm(handledForm.content);
        setTaskFields(getFields(handledForm.content));
      } else {
        history.push('/workflows/status/' + processKey);
      }
    }

    if (contentRef) {
      getTaskForm(contentRef);
    }

    if (templateForm) {
      setProcessInstance(templateForm.process.instance);
      const handledForm = handleUploaderAndDownloaderItems(templateForm);
      setTaskForm(handledForm.content);
      setTaskFields(getFields(handledForm.content));
    }

    if (!contentRef && !templateForm) {
      history.push('/workflows/status/' + processKey);
    }
  }, [templateForm, contentRef]);



  /**
   * -----------------------------------HANDLING FILES----------------------------------------------------
  */
  useEffect(() => {
    if (downloaderName && processInstance) {
      getDownloadedFiles();
    }
  }, [downloaderName]);

  const handleUploaderAndDownloaderItems = (processData) => {
    const form = processData.content;
    if (form && form.rows) {
      const res = form.rows.map(row => {
        const fields = row.fields.map(field => {
          if (field.type == 'file_downloader_folder') {
            field.type = 'downloader';
            if (!downloaderName) {
              setDownloaderName(field.name);
            }
          }

          if (field.type == 'file_uploader') {
            field.type = 'uploader';
            if (!downloaderName) {
              setDownloaderName(field.name);
            }
          }
          return field;
        });
        row.fields = fields;
        return row;
      });

      form.rows = res;
      processData.content = form;
      processData.content.title = processData.content.process_name;
      processData.content.subtitle = processData.content.task_name;
    }
    return processData;
  };

  const getDownloadedFiles = async () => {
    const res = await WorkflowsService.getFiles(processInstance, downloaderName);
    if (res.status == 200) {
      setDownloadedFiles(res.response);
    }
  }
  const getFields = (formData) => {
    let tmpFormValues = {};
    formData.rows.forEach(row => {
      row.fields.forEach(field => {

        if (!field.readOnly) {
          tmpFormValues = {
            ...tmpFormValues,
            [field.name]: field.value
          }
        }
      })
    });

    return tmpFormValues;
  }

  const uploadFiles = async (files, fieldName) => {
    if (files) {
      let formValues = new FormData();
      Object.keys(files).forEach(file => formValues.append('attachment', files[file]));

      formValues.append('variable_id', fieldName);
      formValues.append('process_id', processInstance);
      setShowLoader(true);
      const res = await WorkflowsService.uploadFiles(formValues)
      .catch(() => {
        HandleErrorService.showErrorAlert('Non è stato possibile caricare il file.');
        setTimeout(() => HandleErrorService.hideErrorAlert(false), 3000);
        setShowLoader(false);
      });
      if (res && res.status == 200) {
        getDownloadedFiles();
        setSuccessSentece('File caricati correttamente');
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
        setShowLoader(false);
      } else {
        HandleErrorService.showErrorAlert('Non è stato possibile caricare il file.');
        setTimeout(() => HandleErrorService.hideErrorAlert(false), 3000);
        setShowLoader(false);
      }
    }
  }

  const downloadFile = async (id, fileName) => {
    setShowLoader(true);
    const res = await WorkflowsService.getFile(id)
    .catch(() => {
      HandleErrorService.showErrorAlert('Non è stato possibile eliminare il file.');
      setTimeout(() => HandleErrorService.hideErrorAlert(false), 3000);
      setShowLoader(false);
    });
    if (res) {
      setShowLoader(false);
      const href = URL.createObjectURL(res);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } else {
      HandleErrorService.showErrorAlert('Non è possibile recuperare il file.');
      setShowLoader(false);

    }
  }

  const removeFile =  async (id) => {
    setShowLoader(true);
    const res = await WorkflowsService.removeFile(id)
    .catch(() => {
      HandleErrorService.showErrorAlert('Non è stato possibile eliminare il file.');
      setTimeout(() => HandleErrorService.hideErrorAlert(false), 3000);
      setShowLoader(false);
    });
    if (res && res.status == 200) {
      getDownloadedFiles();
      setSuccessSentece('File eliminato!');
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      setShowLoader(false);
    } else {
      HandleErrorService.showErrorAlert('Non è stato possibile eliminare il file.');
      setTimeout(() => HandleErrorService.hideErrorAlert(false), 3000);
      setShowLoader(false);
    }

  }
  /**
   * -------------------------------------END---------------------------------------------------
  */


  const submit = async (url, values) => {
    const submitted = await WorkflowsService.submitForm(url, values);
    if (submitted && submitted.status == 200) {
      if (submitted.contents[0].followFlow === 'no_further') {
        history.push('/task-completed/' + processKey);
      } else {
        setSuccessSentece('Fase completata!');
        setShowAlert(true);
        setProcessInstance(submitted.contents[0].process.instance);
        const handledForm = handleUploaderAndDownloaderItems(submitted.contents[0]);
        setTaskForm(handledForm.content);
        setTaskFields(getFields(handledForm.content));
        setTimeout(() => setShowAlert(false), 3000);
      }
    }
    else {
      HandleErrorService.showErrorAlert(submitted && submitted.errors ? submitted.errors[0] : constants.defaultErrorMessage);
    }
  }

  const goBack = async () => {
    history.push('/workflows/status/' + processKey);
  }

  return <>
    {showLoader && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
    <Alert className={(!showAlert ? "hide-alert hide-task-alert" : "show-alert show-task-alert") + " col-12 task-alert"} theme="success">
      <i className="fa fa-info mx-2"></i> {successSentence}
    </Alert>
    <Container className='mt-5 mb-5'>
      {taskForm && taskFields &&
      <>
        <Button className="mb-2 btn-success" onClick={() => goBack()}>Torna alla lista dei processi</Button>
        <Card>
          <CardBody className={taskForm && taskForm.task_caption ? 'pr-0' : ''}>
            <Row>
              <Container className="d-flex">
                <Tooltip title={taskForm.process_id}>
                  <p className="mb-1">Processo {taskForm.alternative_process_id}</p>
                </Tooltip>
                <Tooltip title={taskForm.task_id}>
                  <p className="ml-5 mb-1">Task {taskForm.alternative_task_id}</p>
                </Tooltip>

              </Container>
            </Row>
            <Row>
              <Col md={taskForm && taskForm.task_caption ? "8" : "12"}>
                <RenderTemplateForm
                  formData={taskForm}
                  submit={submit}
                  formFields={taskFields}
                  downloadFile={async (id, fileName) => await downloadFile(id, fileName) }
                  uploadFiles={async (files, fieldName) => await uploadFiles(files, fieldName)}
                  removeFile={async (id) => await removeFile(id)}
                  downloadedFiles={downloadedFiles}></RenderTemplateForm>
              </Col>
              {
                taskForm && taskForm.task_caption &&
                <Col md="4" className="task-description-container">
                  <div>{taskForm.task_caption}</div>
                </Col>
              }
            </Row>
          </CardBody>
        </Card>
      </>
      }
    </Container>
  </>
};

export default TaskPage;
