/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Store } from "../../../flux";
import MenuService from "../../../service/menuService";
import SidebarNavItem from "./SidebarNavItem";

const CollapsableNavItem = ({ accordionSummary, menuItemId }) => {
  const [expanded, setExpanded] = useState(false);
  const [accordionDetails, setAccordionDetails] = useState(null);

  const handleChange =
  (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    if (isExpanded) {
      setAccordionDetails(await getMenuItems(menuItemId))
    }
  };

  const getMenuItems = async (id) => {
    const menuItems = await MenuService.getMenu(id);
    Store.reloadMenuCompleted();
    return menuItems.menuItems;
  }

  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={`pl-0 menu-accordion-container ${!expanded ? 'menu-accordion-container-closed' : ''}`}>
      <AccordionSummary className="nav-link dropdown-toggle">{accordionSummary}</AccordionSummary>
      <AccordionDetails className="ml-3">
        {expanded && accordionDetails && accordionDetails.map((item, idx) => (
          item.navigation != 'NAV_ROOT_MENU_ELEMENT' && item.navigation != 'NAV_PARENT_MENU_ELEMENT' &&
            <SidebarNavItem key={idx} item={item} menuVisible={expanded} ></SidebarNavItem>
        ))}
      </AccordionDetails>
    </Accordion>
  )
};

CollapsableNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default CollapsableNavItem;
