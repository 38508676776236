import React, { useEffect, useState } from "react";
import { Container } from "shards-react";

import { useParams } from "react-router-dom";
import PagesService from "../../service/pagesService";
import { useLocation } from "react-router-dom";

const RenderHTMLTemplate = () => {
  const {id} = useParams();
  const [template, setTemplate] = useState();
  const location = useLocation();

  if (id) {
    useEffect(() => {
      async function getTemplate() {
        const data = await PagesService.getPage(id);
        setTemplate(data.template);
      }

      getTemplate();
      // .catch((err) => console.log('ERROR', err));
    }, [id]);
  } else if (location.state.template) {
    useEffect(() => {
      setTemplate(location.state.template);
    }, [location]);
  }


  return (
    <Container fluid className="main-content-container px-4">
      {template &&
        <div dangerouslySetInnerHTML={{__html: template}}>
        </div>
      }
    </Container>
  );
}

export default RenderHTMLTemplate;
