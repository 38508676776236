export default function() {
  return [
    // {
    //   title: "Blog Dashboard",
    //   to: "/blog-overview",
    //   htmlBefore: '<i class="material-icons">edit</i>',
    //   htmlAfter: ""
    // },
    // {
    //   itemLabel: "Home",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   // to: "/renderView/-1",
    //   id: -1,
    //   type: 'portal'
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Ticketing",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/wizard",
    // },
    // {
    //   id: 0,
    //   title: "External App 1",
    //   category: constants.HTML,
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/renderTemplate"
    // },
    // {
    //   id: 1,
    //   title: "External App 2",
    //   category: constants.HTML,
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/renderTemplate"
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   itemLabel: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile",
    //   type: 'portal'
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
