import { Store } from "../flux";

const handleSocketEvents = (messages) => {
  messages.forEach(data => {
    switch (data.action) {
      case 'reloadAll':
        // window.location.reload();
        break;
      case 'reloadMenu':
        Store.reloadMenu();
        break;
      case 'reloadContent':
        // perform /content request
        break;
      case 'reloadNotifies':
        Store.reloadNotifies();
        break;
      default:
        console.log('EVENT NOT HANDLED', data);
        break;
    }
  });
}
export const WebSocketService = {
  handleSocketEvents
}

export default WebSocketService;
