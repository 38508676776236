/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import {
  Col,
  Card,
  CardBody
} from "shards-react";
import { withRouter } from 'react-router';

class NewsMainCardPost extends React.Component {

  render() {
    const {
      post,
      // canManage
    } = this.props;
    return (
      <Col md="12" sm="12" className="mb-4"
      // onClick={() => this.goToNewsDetailPage(post)}
      >
        <Card small className="card-post card-post--1 card-post--aside big-news-container">
          {post.images && post.images[0] &&
            <div
              className="card-post__image news-list-card-post-image"
            >
              <img className="news-list-image" src={post.images[0].bytes ? `data:image/png;base64,${post.images[0].bytes}` : require('../../images/' + post.images[0].filename)} alt={post.images[0].alt}/>
            </div>

          }
          <CardBody className="news-card-body-container">
            <div className="news-card-category-pill-container">
              <span className="news-card-category-pill">{post.category}</span>
            </div>
            <div>
              {/* <span className="card-date">{new Date(post.date).toLocaleDateString()}</span> */}
              <span className="card-date">{post.date}</span>
              <h5 className="card-title">
                {post.title}
              </h5>
              {post.text &&
                <div className="news-body-container">
                  <p className="card-text d-inline-block mb-3">{post.text}</p>
                </div>
              }
            </div>
          </CardBody>
        </Card>
      </Col>
  )}
}

export default withRouter(NewsMainCardPost);
