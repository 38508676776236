/* eslint-disable */
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "shards-react";
import { Column, ColumnData } from "../../classes/WorkflowsTableData";
import TasksPathsModal from "./TasksPathModal";
import WorkflowTable from "./WorkflowTable";

const ClosedWorkflowTable = ({
  dataToShow
}) => {

  const [formattedClosedProcessList, setFormattedClosedProcessList] = useState();
  const [columns, setColumns] = useState([]);
  const [show, setShow] = useState(false);
  const [modalDataToShow, setModalDataToShow] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setColumns();
    let cols = [new Column('Id processo', 'col-uuid'),
    new Column('Percorso tasks', 'col-tasks-path')];

    if (dataToShow.extraColumns) {
      cols = cols.concat(dataToShow.extraColumns.map(item => new Column(item)));
    }
    setColumns(cols);

    setFormattedClosedProcessList();
    if (dataToShow && dataToShow.data) {
      const tmpFormattedClosedProcessList = dataToShow.data.map(item => {
        const statusToShow = formatStatusToShow(item.state);
        const lastTaskToShow = item.tasks[item.tasks.length - 1];

        let phaseColor = lastTaskToShow.endTime ? 'phase-completed-description' : 'phase-in-progress-description';

        if (item.state == 'EXTERNALLY_TERMINATED') {
          phaseColor =   'phase-deleted-description';
        }

        return {
          data: {
            id: new ColumnData(
              <div>
                <Tooltip title={statusToShow.tooltipText}>
                  <p className='mb-0'>{item.alt_id} <span className={"status-point " + statusToShow.statusColor}></span></p>
                </Tooltip>
                <p className="process-dates-container">{new Date(item.startTime).toLocaleString()}{item.endTime ? ' - ' + new Date(item.endTime).toLocaleString() : ''} {item.durationInMillis ? '(durata: ' + formattedDuration(item.durationInMillis) + ')' : ''}</p>
              </div>,
              'col-uuid'
            , item.id),
            tasks: new ColumnData(
              <div className="p-0 col-task">
                {lastTaskToShow &&
                  <Tooltip title={lastTaskToShow.id} key={lastTaskToShow.id}>
                    <div>
                      <div className="d-flex tasks-path-container">
                        <p className={"phase-description mb-0 mr-1 " + phaseColor}>{item.tasks.length} </p>
                        <div className="phase-description-container">
                          <p className={"phase-description mb-0 " + phaseColor}>{lastTaskToShow.name} </p>
                          {lastTaskToShow.worker && <p className={"phase-description mb-0 mt-1 " + phaseColor}>{lastTaskToShow.worker} </p>}
                        </div>
                      </div>
                      <p className="process-dates-container mb-1">{new Date(lastTaskToShow.startTime).toLocaleString()}{lastTaskToShow.endTime ? ' - ' + new Date(lastTaskToShow.endTime).toLocaleString() : ''} {lastTaskToShow.duration ? '(durata: ' + formattedDuration(lastTaskToShow.duration) + ')' : ''}</p>
                      {item.viewTaskHistory && <Button className="btn btn-success" onClick={(e) => openModal(e, item.tasks, item.state)}>Vedi Percorso</Button>}
                    </div>
                  </Tooltip>
                }

              </div>,
              'col-tasks-path'
            ),
            ...addSpecificFields(item)
          }
        }
      });

      setFormattedClosedProcessList(tmpFormattedClosedProcessList);
    }

  }, [dataToShow, dataToShow.data]);

  const formatStatusToShow = (status) => {
    const res = {
      tooltipText: 'in corso',
      statusColor: 'progress-status'
    }

    switch (status) {
      case 'COMPLETED':
        res.tooltipText = 'completato';
        res.statusColor = 'completed-status';
        break;
      case 'EXTERNALLY_TERMINATED':
        res.tooltipText = 'EXTERNALLY_TERMINATED';
        res.statusColor = 'deleted-status';
        break;
      default:
        break;
    }

    return res;
  }

  const addSpecificFields = (process) => {
    let res = {};
    dataToShow.extraColumns.forEach(col => {
      res = {
        ...res,
        [col]: new ColumnData(process.specific_fields[col] ? process.specific_fields[col] : '')
      }
    });
    return res;
  }


  const formattedDuration = (millis) => {
    let res = '';

    const seconds = Math.floor((millis / 1000) % 60);
    const minutes = Math.floor((millis / (1000 * 60)) % 60);
    const hours = Math.floor((millis / (1000 * 60 * 60)) % 24);
    const days = Math.floor((millis / (1000 * 60 * 60 * 24)) )

    days.toString().padStart(2, "0");
    hours.toString().padStart(2, "0");
    minutes.toString().padStart(2, "0");
    seconds.toString().padStart(2, "0");

    if (days > 0) {
      res = days + (days == 1 ? ' giorno' : ' giorni');
    }

    if (hours > 0) {
      res += (res ? ' e ' : '') + hours + (hours == 1 ? ' ora' : ' ore');
    }

    if (minutes > 0 && !days) {
      res += (res ? ' e ' : '') + minutes + ' min.';
    }

    if (seconds > 0 && !hours) {
      res += (res ? ' e ' : '') + seconds + ' sec.';
    }

    return res;
  }

  const openModal = (e, row, processState) => {
    e.stopPropagation();
    setModalDataToShow({tasks: row, processState});
    handleShow();
  }

  return (<>
    <WorkflowTable tableTitle={"Fasi di processo"} isConcludedList={true} dataToShow={formattedClosedProcessList} columns={columns} ></WorkflowTable>
    <TasksPathsModal show={show} handleClose={handleClose} dataToShow={modalDataToShow} />
  </>
  );

};

export default ClosedWorkflowTable;
