import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";
import { Dispatcher, Constants } from "../../../flux";

import { Store } from "../../../flux";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);

  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }

  handleClick() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
    this.props.updateMenuVisible(Store.getMenuState());
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <Col
        tag="aside"
        className={classes}
        // style={{"borderRight": "1px solid #22569f"}}
        lg={{ size: `${this.state.menuVisible ? 2 : 'auto'}` }}
        md={{ size: `${this.state.menuVisible ? 3 : 'auto'}` }}
      >
        {/* eslint-disable-next-line */}
        <span onClick={() => this.handleClick()} className="cursor-pointer">
          <SidebarMainNavbar hideLogo={this.props.hideLogo} menuVisible={this.state.menuVisible}/>
        </span>
        <SidebarSearch />
        <SidebarNavItems menuVisible={this.state.menuVisible}/>
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoo: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogo: false
};

export default MainSidebar;
