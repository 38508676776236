import request from "./base/baseService";
import { constants } from "../config/config";
import axios from 'axios';

const url = `${constants.api.url}`;
const addNewsRoute = constants.api_routes.news_upload;

// idMenu in realtà sarebbe idUser ed in base allo user avremo sidebar differenti
const getNews = async (pageNumber = 0, pageSize = 10) => {
    const iterationId = Math.random();

    return await request({
      url: `${url}/content?itemId=-1&pageNumber=${pageNumber}&pageSize=${pageSize}&iterationId=${iterationId}`,
      method: 'GET'
    })
}

const getNewsDetail = async (id) => {
    const iterationId = Math.random();

    return await request({
      url: `${url}/content?itemId=${id}&iterationId=${iterationId}`,
      method: 'GET'
    })
}

const addNews = async (data) => {
  return await axios.post(`${url}${addNewsRoute.url}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + localStorage.getItem('token')
    },
  })
}


export const NewsService = {
  getNews,
  getNewsDetail,
  addNews
}

export default NewsService;
