/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button } from "shards-react";
import { Column, ColumnData } from "../../classes/WorkflowsTableData";
import ConfirmModal from "../../components/common/ConfirmModal";
import { constants } from "../../config/config";
import HandleErrorService from "../../service/handleErrorService";
import WorkflowsService from "../../service/workflowsService";
import AssigneeModal from "./AssigneeModal";
import WorkflowTable from "./WorkflowTable";
const groupIcon = require("../../images/group-icon.svg");
const userIcon = require("../../images/user-icon.svg");
const ActiveWorkflowTable = ({
  dataToShow,
  processKey,
  canAssign
}) => {

  const [formattedActiveProcessList, setFormattedActiveProcessList] = useState();
  const [show, setShow] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalDataToShow, setModalDataToShow] = useState();
  const [columns, setColumns] = useState([]);
  const [modalConfirmDataToShow, setModalConfirmDataToShow] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setColumns();
    let cols = [new Column('Id processo', null, 'id', true),
    new Column('Owner', null, 'owner', true),
    new Column('Descrizione task', null, 'descriptionTask', true),
    new Column('Data creazione', null, 'creationDate', true)];

    if (canAssign) {
      cols.push(new Column('Azione'));
    }

    const tmpFormattedActiveProcessList = dataToShow.map(item => {
      const data = {
        id: new ColumnData(item.alternative_process_id, '', item.id),
        owner: new ColumnData(
          <div className="d-flex align-items-center">
            {item.assignee_type &&
            <img className={item.assignee_type === 'G' ? "owner-group-icon mr-1" : 'owner-user-icon mr-2'} alt='' src={item.assignee_type === 'G' ? groupIcon : userIcon}/> }
            {item.assignee}
          </div>
        , '', item.assignee, true),
        descriptionTask: new ColumnData(item.name),
        creationDate: new ColumnData(new Date(item.created).toLocaleString(), '', item.created, false, true),
      };

      if (item.canAssign) {
        data.assignee = new ColumnData(
          <>
            <Button className="btn-success" onClick={(e) => openModal(e, item)}>
              Assegna
            </Button>
            {item.canDelete &&
            <Button className="ml-1 btn-danger" onClick={(e) => openDeleteModal(e, item.id)}>
              Elimina processo
            </Button>
            }
          </>
        );
      }

      if (item.canDelete && !canAssign) {
        cols.push(new Column('Azione'));
        data.assignee = new ColumnData(
          <Button>
            Elimina processo
          </Button>
        )
      }

      return {
        data: data,
        contentRef: item.contentReqRef
      }
    });

    setColumns(cols);

    setFormattedActiveProcessList(tmpFormattedActiveProcessList);
  }, [dataToShow]);

  const openModal = (e, row) => {
    e.stopPropagation();
    setModalDataToShow(row);
    handleShow();
  }

  const openDeleteModal = (e, id) => {
    e.stopPropagation();
    setModalConfirmDataToShow({
      id,
      title: 'Elimina processo',
      body: 'Eliminare il processo selezionato?'
    });
    setShowConfirmModal(true);

  }

  const handleConfirmModalClose = async (confirmed) => {
    if (!confirmed) {
      setShowConfirmModal(false);
    } else {
      const res = await WorkflowsService.deleteProcess(modalConfirmDataToShow.id);
      if (res && res.status == 200) {
        window.location.reload();
      } else {
        HandleErrorService.showErrorAlert(res && res.errors ? res.errors[0] : constants.defaultErrorMessage);
      }
    }

  }

  return (<>
    <WorkflowTable processKey={processKey} tableTitle={"Task attivi"} isConcludedList={false} dataToShow={formattedActiveProcessList} columns={columns}></WorkflowTable>
    <AssigneeModal show={show} handleClose={handleClose} dataToShow={modalDataToShow}></AssigneeModal>
    <ConfirmModal show={showConfirmModal} handleClose={handleConfirmModalClose} dataToShow={modalConfirmDataToShow}></ConfirmModal>
  </>
  );

};

export default ActiveWorkflowTable;
