/* eslint-disable */
import React from "react";
import { withRouter } from 'react-router';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { constants } from "../../../../config/config";
import HandleErrorService from "../../../../service/handleErrorService";
import LoginService from "../../../../service/loginService";
// const userImage = require("./../../../../images/avatars/no-avatar.jpg");
class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  async logout() {
    // localStorage.clear();
    const logoffRes = await LoginService.logoff();
    localStorage.clear();

    if (logoffRes && logoffRes.status == 200) {
      window.location.reload();
    } else {
      HandleErrorService.showErrorAlert(logoffRes && logoffRes.errors ? logoffRes.errors[0] : constants.defaultErrorMessage);
      window.location.reload();
    }
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={() => this.toggleUserActions()} className="d-flex align-items-center cursor-pointer user-container">
        <DropdownToggle caret tag={(props) =>  <NavLink {...props} />} className="text-nowrap px-3 pointer">
          {/* <img
            className="user-avatar mr-2"
            src={userImage}
            alt="User Avatar"
          /> */}
          <span className="d-none d-md-inline-block">{localStorage.getItem('username')}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={(props) => <div {...props}></div>} onClick={async () => await this.logout()} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

export default withRouter(UserActions);
