export default function () {
  return {
    "contents": [
      {
        "totalItems": 10,
        "canManage": true,
        "items": [
          {
            "newsId": 1,
            "title": "REVO è la compagnia assicurativa più innovativa all’Italian Insurtech Summit",
            "date": "14 Dicembre 2022",
            "main": true,
            "pubDate": 12225549,
            "category": "Finanza",
            "images": [
              {
                "id": 227,
                "alt": "provaX senza img image #1",
                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/png",
                "filename": "news1.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 2,
            "title": "Azimut Marketplace e REVO sfruttano la blockchain a supporto dei ristoratori con un'innovativa polizza per i dehors",
            "date": "01 Febbraio 2023",
            "pubDate": 12225545,
            "category": "Polizze",
            "images": [
              {
                "id": 222,
                "alt": "prova1 image #1",
                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/png",
                "filename": "news2.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 3,
            "title": "REVO lancia “REVO ParametricX Maltempo Eventi”: risarcimento automatico se il meteo fa saltare l’evento",
            "date": "27 Gennaio 2023",
            "pubDate": 12225545,
            "category": "Polizze",
            "images": [
              {
                "id": 228,
                "alt": "prova1 image #1",
                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/png",
                "filename": "news3.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 4,
            "title": "REVO rafforza l’offerta nelle Specialty Lines: al via le nuove coperture pensate per RC Professionale e D&O",
            "date": "25 Gennaio 2023",
            "pubDate": 1601132,
            "category": "Speciality lines",
            "images": [
              {
                "id": 2,
                "alt": "immagine ELBA",
                "hsize": 15,
                "vsize": 15,
                "mimeType": "image/png",
                "filename": "news4.png",
                "type": "IMAGE"
              }
            ],
            "layout": "secondo"
          },
          {
            "newsId": 5,
            "title": "Da oggi matricola dell’Euronext STAR Milan, REVO si presenta al mercato con brand rinnovato e nuovo sito web.",
            "date": "24 Gennaio 2023",
            "pubDate": 160555,
            "category": "Corporate",
            "images": [
              {
                "id": 1,
                "hsize": 15,
                "vsize": 15,
                "mimeType": "image/jpeg",
                "filename": "news5.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 6,
            "title": "Donne Quotate. Intervista alla Presidente Antonia Boccadoro",
            "date": "21 Gennaio 2023",
            "pubDate": 5,
            "category": "Interviste",
            "images": [
              {
                "id": 233,
                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/jpeg",
                "filename": "news6.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 7,
            "title": "Variazione del capitale sociale conseguente a annullamento dei diritti di assegnazione",
            "date": "21 Gennaio 2023",
            "pubDate": 4,
            "category": "Finanza",
            "images": [
              {
                "id": 232,
                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/jpeg",
                "filename": "news7.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 8,
            "title": "La sfida di seguire il rischio delle imprese Articolo di Simone Lazzaro, Chief Underwriting Officer di REVO.",
            "date": "20 Gennaio 2023",
            "pubDate": 3,
            "category": "Voce ai leader",
            "images": [
              {
                "id": 231,
                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/jpeg",
                "filename": "news8.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 9,
            "title": "Le polizze parametriche nella prospettiva indennitaria.",
            "date": "15 Gennaio 2023",
            "pubDate": 2,
            "category": "Polizze",
            "images": [
              {
                "id": 230,

                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/jpeg",
                "filename": "news9.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          },
          {
            "newsId": 10,
            "title": "Pubblicazione del prospetto informativo relativo all’ammissione alle negoziazioni su Euronext Star Milan.",
            "date": "12 Gennaio 2023",
            "pubDate": 1,
            "category": "Finanza",
            "images": [
              {
                "id": 229,
                "hsize": 0,
                "vsize": 0,
                "mimeType": "image/jpeg",
                "filename": "news10.png",
                "type": "IMAGE"
              }
            ],
            "layout": "default"
          }
        ],
        "type": "NEWS"
      }
    ],
    "status": 200,
    "errors": [],
    "iterationId": 111111111111111100
  }
}
