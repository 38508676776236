import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

import Chart from "../../utils/chart";

class RenderChart extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const data = this.props.data;
    const labels = [...data.serie.map(x => x.label)];
    let values = [...data.serie.map(x => x.value)];
    let maxValue;
    if (!data.autoMax) {
      maxValue = values.reduce((acc, item) => {
        if (acc < item) {
          acc = item;
        }
        return acc;
      }, 0);
    }

    const colors = [
      'rgb(188 193 29)',
      'rgb(235 97 26)',
      'rgb(241 172 35)',
      'rgb(249 219 53)'
    ]

    if (values.length > 10) {
      values.forEach(x => {
        const r = Math.round(Math.random() * 200);
        const g = Math.round(Math.random() * 200);
        const b = Math.round(Math.random() * 200);;
        colors.push('rgb(' + r + ',' + g + ',' + b + ')');
      })
    }

    if (data.percent) {
      const totalValues = values.reduce((acc, item) => {
        acc = acc + item;
        return acc;
      }, 0);

      values = values.map(x => Math.round((x/totalValues) * 100));
    }

    const chartOptions = {
      ...{
        maintainAspectRatio: true,
        responsive: true,
        legend: {
          display: data.legend ? data.legend : false
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              return '';
            },
            afterLabel: function(tooltipItem, dataTooltip) {
              return [
                dataTooltip.labels[tooltipItem.index] + ': ' + (data.serie[0].formattedValue ? data.serie[0].formattedValue : dataTooltip.datasets[0].data[tooltipItem.index]) + (data.percent ? ' %' : '')
              ];
            },
          }
        },
        // pieceLabel: {
        //   // mode 'label', 'value' or 'percentage', default is 'percentage'
        //   mode: 'value',

        //   // precision for percentage, default is 0
        //   precision: 0,

        //   // font size, default is defaultFontSize
        //   fontSize: 18,

        //   // font color, default is '#fff'
        //   fontColor: '#fff',

        //   // font style, default is defaultFontStyle
        //   fontStyle: 'bold',

        //   // font family, default is defaultFontFamily
        //   fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
        // },
        elements: {
          point: {
            radius: 0
          },
          line: {
            tension: 0.33
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: false,
              scaleLabel: false,
              ticks: {
                display: false,
                isplay: false,
                // Avoid getting the graph line cut of at the top of the canvas.
                // Chart.js bug link: https://github.com/chartjs/Chart.js/issues/4790
                suggestedMax: maxValue ? maxValue : Math.max(...values) + 1
              }
            }
          ]

        }
      },

      ...this.props.chartOptions
    };

    const chartConfig = {
        type: data.type,
        data: {
          labels: labels,
          datasets: [{
            data: values,
            backgroundColor: colors,
            hoverBackgroundColor: ['rgb(188 193 29 / 70%)', 'rgb(235 97 26 / 70%)', 'rgb(241 172 35 / 70%)']

          }]
        },
        options: chartOptions,
        ...this.props.chartConfig
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    return (
      <>
        <canvas
          ref={this.canvasRef}
          className={`stats-small-${shortid()}`}
        />
      </>
    );
  }
}

RenderChart.propTypes = {
  /**
   * The Small Stats variation.
   */
  variation: PropTypes.string,
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The percentage number or string.
   */
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Whether is a value increase, or not.
   */
  increase: PropTypes.bool,
  /**
   * The Chart.js configuration object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options object.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  data: PropTypes.array.isRequired,
};

RenderChart.defaultProps = {
  increase: true,
  percentage: 0,
  value: 0,
  label: "Label",
  chartOptions: Object.create(null),
  chartConfig: Object.create(null),
  data: []
};

export default RenderChart;
