/* eslint-disable */
import React from "react";

const DownloadFilesSection = ({
  downloadedFiles,
  label,
  downloadFile,
  removeFile
}) => {

  const getIcon = (fileName) => {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    switch (extension) {
      case 'pdf':
        return require('../../images/icons/pdf_icon.png');
      case 'csv':
      case 'xlsx':
        return require('../../images/icons/xlsx_icon.png');
      case 'doc':
        return require('../../images/icons/doc_icon.png');
      case 'txt':
        return require('../../images/icons/txt_icon.png');
      case 'png':
      case 'svg':
      case 'jpg':
      case 'jpeg':
      default:
        return require('../../images/icons/img_icon.png');
    };
  }

  return (<>
    {label}
    <div className={"donwload-files-section " + (downloadedFiles && downloadedFiles.length > 0 ? "d-flex" : "")}>
      {downloadedFiles && downloadedFiles.length > 0 && downloadedFiles.map((file, index) =>
        <div className="file" key={index}>
          <div className="file-info" onClick={() => downloadFile(file.id, file.fileName)}>
            <img src={getIcon(file.fileName)} />
            <span>{file.fileName}</span>
          </div>
          {removeFile && <span className="remove-file-icon" onClick={() => removeFile(file.id)}>x</span>}
        </div>
      )}
      {(!downloadedFiles || downloadedFiles.length == 0) &&
      <div className="text-center">
        Nessun file presente.
      </div>}
    </div>
  </>
  )
}

export default DownloadFilesSection;
