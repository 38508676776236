/* eslint-disable */
import React from "react";
import { DropdownItem } from "shards-react";
import { useHistory } from 'react-router-dom';
import PagesService from "../../../../service/pagesService";
import HandleErrorService from "../../../../service/handleErrorService";
import { constants } from "../../../../config/config";


const NotificationGroup = ({notificationsGroup, category}) => {
  const history = useHistory();

  const renderNotificationIcon = (category) => {
    switch (category) {
      case 'TO DO LIST':
        return <img alt='' src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAANElEQVQ4y2NgGC6AiSEaCJmI1xDN8B8Io4Gs/3ggJRpIdtJoKA0OwAh2EuNgCiWSnTS0AQBHM2g9pmP6aAAAAABJRU5ErkJggg==`}/>
      case 'PROMEMORIA':
        return <img alt='' src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAA40lEQVQ4y9XQO0oDURgF4I9AILOFWCikshPJFiysRQgM+Gi1VLScBaS1TamdWGUTFj4qOxUiJEUknaDNbzMadWZQQRTPae7A+eDe4TdT18xPNalULf9qqpfNE3035kAqhBTMutaXFMGJMLKIhp4QehpYMBSOP87XhYkWNo1F3rENtEyEtfe3Hwkd7L6OX7qDjjB8+5JV4QLzngrg0QwuhZUpOBK2cFCYh9DFtnA4BedCG1el4BRt4WwK9mUSPJSCeyQye8VfGxWtzP8HWSXIvjevIPFJfzZ3Qrj9Olg2MLDkT/IM0MfRl0CPecsAAAAASUVORK5CYII=`}/>
      default:
        return <img alt='' src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAh0lEQVQ4y72TSw6AIAxEX0w8IAsOhdeQQxG8hR6grgxK/QBG2+08MlNa+Lt6LCORhYXIiKW/kxsmJOsJcy7ucEq8taPTwHApF4RBm5GHNseouXdAZdnFt+o9DQg2Ab4I8AkIRUBIwFwEzC+A8DjUzJIvAvyLsVZ/XPVqNCxfw3pXH1DTiX5RK5PRJwgzq++YAAAAAElFTkSuQmCC`}/>
    }
  }

  const renderEvent = async (itemAction, item) => {
    switch (itemAction.action) {
      case "GOTO_CONTENT":
        const res = await PagesService.getContent(item.id, itemAction.actionRef);
        if (res && res.status == 200 && res.contents && res.contents[0]) {
          const dataToRender = res.contents[0];
          if (dataToRender.type == 'WORKFLOW_FORM') {
            history.push({
              pathname: "/task/" + dataToRender.process.key,
              state: {templateForm: dataToRender}
            });
          } else {
            history.push({
              pathname: "/renderContentRef",
              state: {template: itemAction.actionRef}
            });
          }
        } else {
          HandleErrorService.showErrorAlert(res && res.errors ? res.errors[0] : constants.defaultErrorMessage);
        }
        break;
      case "GOTO_MENUITEM":
        history.push({
          pathname: "/renderView/" + item.contentRef
        });
        break;
      default:
        break;
    }
  }

  return (
  <>
    <div className="category-name">{category} ({notificationsGroup.length})</div>
    {notificationsGroup.map((item, idx) => (
      <DropdownItem key={idx} className={`notification-highlighting-${item.highlighting} notification-container`} onClick={item.actions && item.actions.ONCLICK ? async() => await renderEvent(item.actions.ONCLICK, item) : null}>
        <div className="notification__icon-wrapper">
          <div className="notification__icon">
            {/* {renderNotificationIcon(item.category)} */}
            {item.icon && <img alt={item.icon.alt} src={`data:image/png;base64,${item.icon.bytes}`} />}
            {!item.icon && <img alt="icona notifica" src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAh0lEQVQ4y72TSw6AIAxEX0w8IAsOhdeQQxG8hR6grgxK/QBG2+08MlNa+Lt6LCORhYXIiKW/kxsmJOsJcy7ucEq8taPTwHApF4RBm5GHNseouXdAZdnFt+o9DQg2Ab4I8AkIRUBIwFwEzC+A8DjUzJIvAvyLsVZ/XPVqNCxfw3pXH1DTiX5RK5PRJwgzq++YAAAAAElFTkSuQmCC`} />}
          </div>
        </div>
        <div className="notification__content">
          <span className="notification__category">{item.category} {item.sourceApplication ? ' - ' + item.sourceApplication : ''}</span>
          <h6 className="mb-0">
            {item.title} {item.issuer ? ' - ' + item.issuer : ''}
          </h6>
          <p>{item.message}</p>
        </div>
      </DropdownItem>
    ))}
  </>
  );
}

export default NotificationGroup;
